import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Create, List, Detail } from "../screens";
import { Page } from "app/shared";

function Routes() {
  const { path } = useRouteMatch();

  return (
    <Page.Main>
      <Switch>
        <Route path={`${path}/create/:id`} component={Create} exact />
        <Route path={`${path}/create`} component={Create} exact />
        <Route path={`${path}/:project_id`} component={Detail} />
        <Route path={path} component={List} exact />
      </Switch>
    </Page.Main>
  );
}

export default Routes;
