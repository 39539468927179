import { projectService } from "services";
import { LOAD_PROJECTS, LOAD_PROJECT, LOADING } from "../types";

/**
 * Load all projects from api
 *
 */
export function loadProjects() {
  return async function (dispatch) {
    dispatch({
      type: LOADING,
      payload: true,
    });
    const projects = await projectService.list();
    dispatch({
      type: LOAD_PROJECTS,
      payload: [...projects],
    });
    dispatch({
      type: LOADING,
      payload: false,
    });
  };
}

/**
 * Load a project by id
 *
 */
export function loadProject(id) {
  return async function (dispatch) {
    dispatch({
      type: LOADING,
      payload: true,
    });
    const project = await projectService.find(id);
    dispatch({
      type: LOAD_PROJECT,
      payload: project,
    });
    dispatch({
      type: LOADING,
      payload: false,
    });
  };
}
