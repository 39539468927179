import {
  LOADING,
  LOAD_REQUISITES,
  POP_REQUISITE,
  PUSH_REQUISITE,
} from "../types";
import remove from "lodash/remove";

const INITIAL_STATE = {
  requisites: [],
  loading: false,
};

function handleMerge(state, requisite) {
  const index = state.requisites.indexOf(
    state.requisites.find((r) => r.id === requisite.id)
  );

  if (index !== -1) {
    state.requisites[index] = requisite;
  } else {
    state.requisites.push(requisite);
  }

  return {
    ...state,
  };
}

function handleRemove(state, requisite) {
  return {
    ...state,
    requisites: remove(state.requisites, (r) => r.id !== requisite.id),
  };
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_REQUISITES:
      return { ...state, requisites: action.payload };
    case PUSH_REQUISITE:
      return handleMerge({ ...state }, action.payload);
    case POP_REQUISITE:
      return handleRemove({ ...state }, action.payload);
    case LOADING:
      return { ...state, loading: action.payload };
  }
  return { ...state };
}
