import apiService from "services/apiService";
import ResourceService from "../resourceService";

export class MemberService extends ResourceService {
  /**
   * Resource route
   *
   */
  public route: string = "members";

  /**
   * Add a member to a platform
   *
   */
  public async addMember(platform_id: string, user_id: string) {
    try {
      return await apiService().post("/members/add", {
        platform_id,
        user_id,
      });
    } catch (e) {
      const { message } = e?.response?.data || { message: false };
      throw new Error(
        message || "Algo deu errado, por favor tente novamente mais tarde"
      );
    }
  }

  /**
   * Remove a member to a platform
   *
   */
  public async removeMember(platform_id: string, user_id: string) {
    try {
      return await apiService().post("/members/remove", {
        platform_id,
        user_id,
      });
    } catch (e) {
      const { message } = e?.response?.data || { message: false };
      throw new Error(
        message || "Algo deu errado, por favor tente novamente mais tarde"
      );
    }
  }
}

export default new MemberService();
