import React from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";

import { ModalProvider } from "app/shared";
import { Navbar } from "../components";
import { Container } from "./styles";

import { Platform, Profile, Project, Team } from "../screens";
import { AuthGuard } from "app/shared/guards";

function Routes() {
  const { path } = useRouteMatch();

  return (
    <ModalProvider>
      <Navbar />
      <Container>
        <Switch>
          <AuthGuard path={`${path}projects`} component={Project.Routes} />
          <AuthGuard path={`${path}platforms`} component={Platform.Routes} />
          <AuthGuard path={`${path}feed`} component={Profile.Routes} />
          <AuthGuard path={`${path}teams`} component={Team.Routes} />
          <Redirect path={path} to={`${path}feed`} />
        </Switch>
      </Container>
    </ModalProvider>
  );
}

export default Routes;
