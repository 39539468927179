import axios from "axios";

const apiService = function () {
  const token = localStorage.getItem("__authdb");

  const authHeaders = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...authHeaders,
  };

  return axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}/api`,
    headers,
  });
};

export default apiService;
