import React, { useState } from "react";
import { Form, Input, Submit } from "@startaideia/react-forms";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";

import { authService } from "services";
import { useModal } from "app/shared";

function PasswordModal() {
  const [loading, setLoading] = useState(false);
  const { closeModal } = useModal();

  /**
   * Handle form submit
   *
   */
  async function handleSubmit(formData) {
    setLoading(true);
    try {
      await authService.updatePassword(formData);
      toast("Senha alterada com sucesso!", {
        type: toast.TYPE.SUCCESS,
      });
      setLoading(false);
      closeModal();
    } catch ({ message }) {
      setLoading(false);
      toast(message, {
        type: toast.TYPE.ERROR,
      });
    }
  }

  return (
    <Form onSubmit={handleSubmit} className="p-5">
      <Input.Password isRequired name="current_password" label="Senha atual" />
      <Input.Password isRequired name="password" label="Nova senha" />
      <Input.Password
        isRequired
        name="password_confirmation"
        label="Confirme a nova senha"
      />
      <Form.Col>
        {loading ? (
          <div className="text-center py-5">
            <Loader type="TailSpin" width="50" color="var(--gray-500)" />
          </div>
        ) : (
          <Submit>Alterar minha senha</Submit>
        )}
      </Form.Col>
    </Form>
  );
}

export default PasswordModal;
