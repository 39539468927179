import { LOADING, LOAD_PLATFORM, LOAD_PLATFORMS } from "../types";

const INITIAL_STATE = {
  platform: null,
  platforms: [],
  loading: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_PLATFORMS:
      return { ...state, platforms: action.payload };
    case LOAD_PLATFORM:
      return { ...state, platform: action.payload };
    case LOADING:
      return { ...state, loading: action.payload };
  }
  return { ...state };
}
