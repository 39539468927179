import styled from "styled-components";

export const ScrollContainer = styled.div`
  padding-bottom: 10px;
  white-space: nowrap;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-100);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--gray-300);
    cursor: pointer;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Group: any = styled.div`
  box-shadow: 1px 2px 2px var(--gray-100);
  grid-template-rows: 50px auto 50px;
  border: 1px solid var(--gray-100);
  background: var(--white);
  display: inline-grid;
  border-radius: 5px;
  height: 600px;
  width: 300px;
  margin: 10px;
`;

Group.Header = styled.div`
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  display: flex;
`;

Group.Body = styled.ul`
  overflow-y: scroll;
  list-style: none;
  padding: 0px;
  margin: 0px;
`;

Group.Item = styled.li`
  box-shadow: 1px 2px 2px var(--gray-100);
  border: 1px solid var(--gray-100);
  white-space: normal;
  border-radius: 5px;
  transition: 0.25s;
  padding: 10px;
  margin: 5px;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }

  &:active {
    transform: scale(1);
  }
`;

Group.Item.Footer = styled.div`
  color: var(--gray-500);
  font-size: 12px;
  display: block;
`;

Group.Label = styled.div`
  border: 1px solid var(--gray-200);
  background: var(--gray-50);
  color: var(--gray-500);
  display: inline-block;
  border-radius: 10px;
  font-weight: bold;
  padding: 2px 5px;
  font-size: 10px;
`;

Group.Footer = styled.div`
  align-items: center;
  transition: 0.25s;
  padding: 0px 10px;
  display: flex;

  &:hover {
    background: var(--gray-50);
    cursor: pointer;
  }
`;
