import React from "react";
import { Container, Image } from "./styles";
import underConstruction from "assets/images/no_data.svg";
import Text from "../Text";

function Empty() {
  return (
    <Container>
      <Image src={underConstruction} />
      <Text size={40} value="Nada encontrado" />
      <Text
        size={18}
        className="mt-2"
        value="Não foi encontrado nada por aqui"
      />
    </Container>
  );
}

export default Empty;
