import React, { useMemo } from "react";
import { Container, Row, Col } from "react-grid-system";
import { connect } from "react-redux";
import { VictoryPie } from "victory";

import { Content } from "./styles";
import { Card } from "app/shared";

function Dashboard({ project }) {
  /* Chart project data */
  const data = useMemo(
    function () {
      return [
        {
          y: project?.current_completness,
        },
        {
          y: project?.maximum_completness - project?.current_completness,
        },
      ];
    },
    [project]
  );

  return (
    <Container>
      <Row>
        <Col xs={12} md={4}>
          <Content>
            <Card>
              <Card.Body>
                <VictoryPie
                  colorScale={["#FF4080", "#e6e6e6"]}
                  data={data}
                  innerRadius={100}
                  padAngle={2}
                  style={{
                    labels: {
                      fill: "transparent",
                    },
                  }}
                />
                <Content.Amount>
                  {project?.current_completness_percent}%
                </Content.Amount>
              </Card.Body>
            </Card>
          </Content>
        </Col>
      </Row>
    </Container>
  );
}

function mapStateToProps({ projects }) {
  return {
    project: projects.project,
  };
}

export default connect(mapStateToProps)(Dashboard) as any;
