import styled from "styled-components";

export const Card: any = styled.div`
  flex-direction: column;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
`;

Card.Title = styled.div`
  padding: 10px;
`;

Card.Body = styled.ul`
  list-style: none;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
`;

Card.Item = styled.li`
  display: flex;
  padding: 5px 10px;

  &:hover {
    background: var(--gray-50);
    cursor: pointer;
  }
`;

Card.Slot = styled.div`
  align-items: center;
  font-size: 16px;
  display: flex;
  padding: 10px 5px;
`;
