import React from "react";
import { connect } from "react-redux";

import { Container, Card, Avatar } from "./styles";
import { Text } from "app/shared";

function UserCard({ user }) {
  return (
    <Container>
      <Card>
        {user && <Avatar email={user?.email} size={120} />}
        <Text
          value={`${user?.first_name} ${user?.last_name}`}
          size="24"
          className="mt-3"
        />
        <Text value={user?.email} className="mt-2" />
      </Card>
    </Container>
  );
}

function mapStateToProps({ app }) {
  return {
    user: app.user,
  };
}

export default connect(mapStateToProps)(UserCard) as any;
