import React from "react";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import ptBrStrings from "react-timeago/lib/language-strings/pt-br";
import Loader from "react-loader-spinner";
import TimeAgo from "react-timeago";

import { Text } from "app/shared/components";
import { Container, Card } from "./styles";
import { useFetch } from "app/shared";

const formatter = buildFormatter(ptBrStrings);

function Notificaitons() {
  const { data } = useFetch("/auth/notifications");

  if (!data) {
    return (
      <div className="text-center py-5">
        <Loader type="TailSpin" width="50" color="var(--gray-500)" />
      </div>
    );
  }

  return (
    <Container>
      <Text value="Notificações" size={18} className="mb-3 ml-2" />
      {data?.map(function ({ id, data, created_at }) {
        return (
          <Card key={id}>
            <Text
              value={<TimeAgo date={created_at} formatter={formatter} />}
              align="right"
              display="block"
              size={10}
            />
            <Text value={data.title} size={12} weight="bold" display="block" />
            <Text
              value={data.description}
              size={12}
              className="mt-2"
              display="block"
            />
          </Card>
        );
      })}
    </Container>
  );
}

export default Notificaitons;
