import apiService from "../apiService";

export default class ResourceService {
  /**
   * Resource route
   *
   */
  public route: string = "";

  /**
   * List all resources
   *
   */
  public list(query = "") {
    return apiService()
      .get(`${this.route}${query}`)
      .then(function ({ data }) {
        return data.data;
      })
      .catch(function (e) {
        const { message } = e?.response?.data || { message: false };
        return (
          message || "Algo deu errado, por favor tente novamente mais tarde"
        );
      });
  }

  /**
   * Find a resource by id
   *
   */
  public find(id) {
    return apiService()
      .get(`${this.route}/${id}`)
      .then(function ({ data }) {
        return data.data;
      })
      .catch(function (e) {
        const { message } = e?.response?.data || { message: false };
        return (
          message || "Algo deu errado, por favor tente novamente mais tarde"
        );
      });
  }

  /**
   * Create a new resource
   *
   */
  public async create(body) {
    try {
      const { data } = await apiService().post(this.route, body);
      return data.data;
    } catch (e) {
      const { message } = e?.response?.data || { message: false };
      throw new Error(
        message || "Algo deu errado, por favor tente novamente mais tarde"
      );
    }
  }

  /**
   * Update a register
   *
   */
  public async update(body, id) {
    try {
      const { data } = await apiService().put(`${this.route}/${id}`, body);
      return data.data;
    } catch (e) {
      const { message } = e?.response?.data || { message: false };
      throw new Error(
        message || "Algo deu errado, por favor tente novamente mais tarde"
      );
    }
  }

  /**
   * Remove a resource
   *
   */
  public async destroy(id) {
    try {
      const { data } = await apiService().delete(`${this.route}/${id}`);
      return data.data;
    } catch (e) {
      const { message } = e?.response?.data || { message: false };
      throw new Error(
        message || "Algo deu errado, por favor tente novamente mais tarde"
      );
    }
  }

  /**
   * Save a record
   *
   */
  public async save(body, id?) {
    if (id) {
      return await this.update(body, id);
    }
    return await this.create(body);
  }
}
