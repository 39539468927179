import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { authService } from "services";
import { appActions } from "store";

function AuthGuard({ loadUser, ...props }: any) {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState();

  useEffect(
    function () {
      authService.refreshToken().then(function (token) {
        setToken(token);
        setLoading(false);
        loadUser(token);
      });
    },
    [loadUser]
  );

  function handleRender() {
    if (loading) {
      return <p>carregando</p>;
    }
    if (!token) {
      return <Redirect to="/auth/login" />;
    }
    return <Route {...props} />;
  }

  return handleRender();
}

function mapDispatchToProps(dispatch) {
  return {
    loadUser: (user) => dispatch(appActions.loadUser(user)),
  };
}

export default connect(null, mapDispatchToProps)(AuthGuard) as any;
