import React from "react";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import Routes from "./routes";

function Main({ store }) {
  return (
    <Provider store={store}>
      <Routes />
      <ToastContainer />
    </Provider>
  );
}

export default Main;
