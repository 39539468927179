import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import { requisitesReducer } from "./requisites";
import { platformsReducer } from "./platforms";
import { projectsReducer } from "./projects";
import { versionsReducer } from "./versions";
import { personasReducer } from "./personas";
import { stagesReducer } from "./stages";
import { tasksReducer } from "./tasks";
import { appReducer } from "./app";

import thunk from "redux-thunk";

const reducers = combineReducers({
  requisites: requisitesReducer,
  platforms: platformsReducer,
  projects: projectsReducer,
  personas: personasReducer,
  versions: versionsReducer,
  stages: stagesReducer,
  tasks: tasksReducer,
  app: appReducer,
});

const store = createStore(
  reducers,
  composeWithDevTools({ trace: true })(applyMiddleware(thunk))
);

export { requisitesActions } from "./requisites";
export { platformsActions } from "./platforms";
export { projectsActions } from "./projects";
export { personasActions } from "./personas";
export { versionsActions } from "./versions";
export { stagesActions } from "./stages";
export { tasksActions } from "./tasks";
export { appActions } from "./app";

export default store;
