import ResourceService from "../resourceService";

export class ReleaseService extends ResourceService {
  /**
   * Resource route
   *
   */
  public route: string = "releases";
}

export default new ReleaseService();
