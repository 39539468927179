import styled from "styled-components";

export const Main: any = styled.div`
  justify-content: ${({ justifyContent }: any) => justifyContent || "normal"};
  vertical-align: top;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;

  &.flex {
    display: flex;
  }
`;

export const Aside: any = styled.div`
  display: inline-block;
  position: absolute;
  width: 280px;
  top: 0;
`;

export const Content: any = styled.div`
  width: calc(100% - 280px);
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
`;
