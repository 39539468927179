import apiService from "services/apiService";
import ResourceService from "../resourceService";

export class PlatformService extends ResourceService {
  /**
   * Resource route
   *
   */
  public route: string = "platforms";

  /**
   * Find a resource by id
   *
   */
  public find(id) {
    return apiService()
      .get(`${this.route}/${id}?include=project`)
      .then(function ({ data }) {
        return data.data;
      })
      .catch(function (e) {
        const { message } = e?.response?.data || { message: false };
        return (
          message || "Algo deu errado, por favor tente novamente mais tarde"
        );
      });
  }
}

export default new PlatformService();
