import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CgUserAdd } from "react-icons/cg";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { mutate } from "swr";

import { SearchInput, Card, Button, Text, useFetch } from "app/shared";
import { Container, DropDown } from "./styles";
import { memberService } from "services";

function MemberSearch({ platform, currentMembers = [] }) {
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [filter, setFilter] = useState("");
  const { data } = useFetch("/members");
  const ref = useRef<any>(null);

  /* Filtered members array */
  const computedData = useMemo(
    function () {
      return data
        ?.filter(function ({ id: memberId }) {
          return !currentMembers.find(({ id }) => id === memberId);
        })
        .filter(function ({ first_name, last_name, email, title }) {
          return (
            first_name.toLowerCase().indexOf(filter) !== -1 ||
            last_name.toLowerCase().indexOf(filter) !== -1 ||
            email.toLowerCase().indexOf(filter) !== -1 ||
            title.toLowerCase().indexOf(filter) !== -1
          );
        });
    },
    [currentMembers, data, filter]
  );

  /* Computed is open state */
  const isOpen = useMemo(
    function () {
      return filter.length >= 2 && toggle && computedData.length > 0;
    },
    [computedData, filter, toggle]
  );

  /* Handle click outside to closes dropdown*/
  const handleClickOutside = useCallback(
    function (e) {
      if (!ref.current) return;

      if (!ref.current.contains(e.target)) {
        setToggle(false);
      }
    },
    [ref, setToggle]
  );

  /* Add a member to a platform */
  function handleAddMember(user_id) {
    return async function () {
      try {
        setLoading(true);
        const { data } = await memberService.addMember(platform.id, user_id);
        setLoading(false);
        mutate(`/platforms/${platform?.id}/members`);
        toast(data.message, {
          type: toast.TYPE.SUCCESS,
        });
      } catch ({ message }) {
        setLoading(false);
        toast(message, {
          type: toast.TYPE.ERROR,
        });
      }
    };
  }

  /* Adds mousedown event listener */
  useEffect(
    function () {
      document.addEventListener("mousedown", handleClickOutside);
      return function () {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    },
    [handleClickOutside]
  );

  return (
    <Container ref={ref}>
      <SearchInput
        onInput={setFilter}
        onFocus={() => setToggle(true)}
        size="400px"
      />
      {isOpen && (
        <DropDown>
          <Card>
            <Card.Table>
              <Card.Table.Body>
                {computedData.map(function (member) {
                  return (
                    <Card.Table.Body.Row key={member?.id}>
                      <Card.Table.Body.Cell>
                        <div className="flex">
                          <Card.Table.Gravatar
                            email={member?.email}
                            size={32}
                          />
                          <div>
                            <div className="mb-1">
                              {member.first_name} {member.last_name}
                            </div>
                            <Text value={member.email} display="block" />
                          </div>
                        </div>
                      </Card.Table.Body.Cell>
                      <Card.Table.Body.Cell>
                        <Text value={member.title} display="block" />
                      </Card.Table.Body.Cell>

                      <Card.Table.Body.Cell>
                        {loading && (
                          <Loader
                            type="TailSpin"
                            color="var(--gray-500)"
                            width="30"
                          />
                        )}
                        {!loading && (
                          <Button
                            onClick={handleAddMember(member?.id)}
                            title="Adicionar ao time"
                          >
                            <CgUserAdd />
                          </Button>
                        )}
                      </Card.Table.Body.Cell>
                    </Card.Table.Body.Row>
                  );
                })}
              </Card.Table.Body>
            </Card.Table>
          </Card>
        </DropDown>
      )}
    </Container>
  );
}

function mapStateToProps({ platforms }) {
  return {
    platform: platforms.platform,
  };
}

export default connect(mapStateToProps)(MemberSearch) as any;
