import {
  LOAD_VERSIONS,
  POP_VERSION,
  PUSH_VERSION,
  TOGGLE_VERSION,
} from "../types";
import { versionService } from "services";
import { LOADING } from "store/requisites/types";

/**
 * Load all versions from a platform
 *
 */
export function loadVersions(platformId) {
  return async function (dispatch) {
    dispatch({
      type: LOADING,
      payload: true,
    });
    const versions = await versionService.list(
      `?filter[platform_id]=${platformId}`
    );
    dispatch({
      type: LOAD_VERSIONS,
      payload: [...versions],
    });
    dispatch({
      type: LOADING,
      payload: false,
    });
  };
}

/**
 * Push version into array
 *
 */
export function pushVersion(version) {
  return {
    type: PUSH_VERSION,
    payload: version,
  };
}

/**
 * Pop version from array
 *
 */
export function popVersion(version) {
  return {
    type: POP_VERSION,
    payload: version,
  };
}

/**
 * Toggle version selection
 *
 */
export function toggleVersion(version) {
  return {
    type: TOGGLE_VERSION,
    payload: version,
  };
}
