import styled from "styled-components";

const Button = styled.button`
  border: 1px solid var(--gray-100);
  border-color: var(--pink-400);
  background: var(--pink-400);
  display: inline-flex;
  color: var(--white);
  align-items: center;
  border-radius: 17px;
  padding: 0px 15px;
  transition: 0.25s;
  font-weight: 500;
  font-size: 12px;
  outline: none;
  height: 32px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.9);
  }
`;

export default Button;
