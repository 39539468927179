import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 400px;
`;

export const DropDown: any = styled.div`
  position: absolute;
  z-index: 9;
  top: 30px;
`;
