import {
  SELECT_EPIC,
  LOAD_EPICS,
  PUSH_TASK,
  POP_TASK,
  LOADING_TASKS,
  SET_LINKS,
} from "../types";
import { LOADING } from "store/platforms/types";
import { taskService } from "services";

/**
 * Load all platforms epics
 *
 */
export function loadEpics(platformId) {
  return async function (dispatch) {
    dispatch({
      type: LOADING,
      payload: true,
    });
    const epics =
      (await taskService.list(
        `?filter[platform_id]=${platformId}&filter[task_id]=null&include=personas,medias`
      )) || [];
    dispatch({
      type: LOAD_EPICS,
      payload: [...epics],
    });
    dispatch({
      type: LOADING,
      payload: false,
    });
  };
}

/**
 * Select an epic
 *
 */
export function selectEpic(epic, platformId) {
  return async function (dispatch) {
    dispatch({
      type: LOADING_TASKS,
      payload: true,
    });
    const tasks =
      (await taskService.list(
        `?filter[platform_id]=${platformId}&filter[task_id]=${epic?.id}&include=personas,version,medias`
      )) || [];
    dispatch({
      type: SELECT_EPIC,
      payload: { tasks, epic },
    });
    dispatch({
      type: LOADING_TASKS,
      payload: false,
    });
  };
}

/**
 * Add a task to the list
 *
 */
export function pushTask(task) {
  return {
    type: PUSH_TASK,
    payload: task,
  };
}

/**
 * Remove a task to the list
 *
 */
export function popTask(task) {
  return {
    type: POP_TASK,
    payload: task,
  };
}

/**
 * Set task links
 *
 */
export function setLinks(links) {
  return {
    type: SET_LINKS,
    payload: links,
  };
}
