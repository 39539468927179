import { LOADING, LOAD_PROJECT, LOAD_PROJECTS } from "../types";

const INITIAL_STATE = {
  project: null,
  projects: [],
  loading: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_PROJECTS:
      return { ...state, projects: action.payload };
    case LOAD_PROJECT:
      return { ...state, project: action.payload };
    case LOADING:
      return { ...state, loading: action.payload };
  }

  return { ...state };
}
