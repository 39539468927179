import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { Text } from "app/shared";
import { Group } from "./styles";

function PlatformCard({ platform }) {
  const history = useHistory();

  /* Computed completness */
  const completness = useMemo(
    function () {
      return platform?.current_completness_percent || 0;
    },
    [platform]
  );

  /**
   * Handle navigate
   *
   */
  function handleNavigate(path: string) {
    return function () {
      history.push(path);
    };
  }

  return (
    <Group>
      <Group.Title
        onClick={handleNavigate(`/projects/${platform?.project?.id}`)}
      >
        <Group.Title.Icon name={platform?.project?.title} size="25px" />
        <Text value={platform?.project?.title} />
      </Group.Title>
      <Group.Body>
        <Group.Body.Slot>
          <Group.Body.Icon name={platform?.title} size={50} />
        </Group.Body.Slot>
        <Group.Body.Slot>
          <Text value={platform?.title} size={16} />
          <Text
            value={`Em desenvolvimento (${completness}%)`}
            size={10}
            className="mt-1 mb-1"
          />
        </Group.Body.Slot>
      </Group.Body>
    </Group>
  );
}

function mapStateToProps({ platforms }) {
  return {
    platform: platforms.platform,
  };
}

export default connect(mapStateToProps)(PlatformCard) as any;
