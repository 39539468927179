import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import { FaCogs } from "react-icons/fa";
import { connect } from "react-redux";

import { Button, Card, Protected, SearchInput, useNavigate } from "app/shared";
import { platformsActions } from "store";
import { Link } from "./styles";

function List({ loadPlatforms, platforms, loading }) {
  const [filter, setFilter] = useState<string>("");
  const { project_id } = useParams<any>();
  const { navigate } = useNavigate();

  /* Load all project platforms */
  useEffect(
    function () {
      loadPlatforms(project_id);
    },
    [loadPlatforms, project_id]
  );

  return (
    <Card>
      <Card.Head>
        <Card.Title>Plataformas</Card.Title>
        <SearchInput onInput={setFilter} size="200px" />
      </Card.Head>

      <Card.Body>
        <Protected resource="members" action="create">
          <Button onClick={navigate(`/projects/${project_id}/platforms/form`)}>
            Criar plataforma
          </Button>
        </Protected>
      </Card.Body>

      {loading && (
        <div className="text-center py-5">
          <Card.Body>
            <Loader type="TailSpin" color="var(--gray-500)" />
          </Card.Body>
        </div>
      )}

      {!loading && (
        <Card.Table>
          <Card.Table.Head>
            <Card.Table.Head.Row>
              <Card.Table.Head.Cell>Plataformas</Card.Table.Head.Cell>
              <Card.Table.Head.Cell width="100px">
                Progresso
              </Card.Table.Head.Cell>
              <Protected resource="platforms" action="update">
                <Card.Table.Head.Cell width="100px" />
              </Protected>
            </Card.Table.Head.Row>
          </Card.Table.Head>
          <Card.Table.Body>
            {platforms
              .filter((p) => p.title.toLowerCase().indexOf(filter) !== -1)
              .map(function ({ id, title, current_completness_percent }) {
                return (
                  <Card.Table.Body.Row key={id}>
                    <Card.Table.Body.Cell>
                      <Link to={`/platforms/${id}`}>
                        <Card.Table.Avatar name={title} size={32} />
                        {title}
                      </Link>
                    </Card.Table.Body.Cell>
                    <Card.Table.Body.Cell className="text-right">
                      <small>{current_completness_percent}%</small>
                    </Card.Table.Body.Cell>
                    <Card.Table.Body.Cell>
                      <div className="flex flex-end">
                        <Protected resource="projects" action="update">
                          <FaCogs
                            onClick={navigate(
                              `/projects/${project_id}/platforms/${id}/edit`
                            )}
                            className="hoverble mr-2"
                          />
                        </Protected>
                      </div>
                    </Card.Table.Body.Cell>
                  </Card.Table.Body.Row>
                );
              })}
          </Card.Table.Body>
        </Card.Table>
      )}
    </Card>
  );
}

function mapStateToProps({ platforms }) {
  return {
    platforms: platforms.platforms,
    loading: platforms.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadPlatforms: (project_id) =>
      dispatch(platformsActions.loadPlatforms(project_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
