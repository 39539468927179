import useSWR from "swr";

import { apiService } from "services";

export default function (url: string, condition = true) {
  const { data, error, mutate } = useSWR(url, async (url) => {
    if (!condition || condition === undefined) {
      return null;
    }

    const response = await apiService().get(url);

    return response.data.data;
  });

  return { data, error, mutate };
}
