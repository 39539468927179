import styled from "styled-components";
import Gravatar from "react-gravatar";

export const Container = styled.div``;

export const Card = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  padding: 50px 0px;
  padding: 10px;
  display: flex;
`;

export const Avatar = styled(Gravatar)`
  border-radius: 50%;
`;
