import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { List, Form } from "../screens";

function Routes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:persona_id/edit`} component={Form} exact />
      <Route path={`${path}/form`} component={Form} exact />
      <Route path={path} component={List} exact />
    </Switch>
  );
}

export default Routes;
