import React from "react";
import { useParams } from "react-router-dom";
import { GoDashboard } from "react-icons/go";
import { CgWebsite } from "react-icons/cg";
import { BsPeople } from "react-icons/bs";

import { useNavigate } from "app/shared";
import { List } from "./styles";

function Sidebar() {
  const { project_id } = useParams<any>();
  const { navigate } = useNavigate();

  return (
    <List>
      <List.Header>Menu</List.Header>
      <List.Item onClick={navigate(`/projects/${project_id}`)}>
        <List.Icon>
          <GoDashboard />
        </List.Icon>
        <List.Text>Inicio</List.Text>
      </List.Item>
      <List.Item onClick={navigate(`/projects/${project_id}/platforms`)}>
        <List.Icon>
          <CgWebsite />
        </List.Icon>
        <List.Text>Plataformas</List.Text>
      </List.Item>
      <List.Item onClick={navigate(`/projects/${project_id}/personas`)}>
        <List.Icon>
          <BsPeople />
        </List.Icon>
        <List.Text>Personas</List.Text>
      </List.Item>
    </List>
  );
}

export default Sidebar as any;
