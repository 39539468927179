import styled from "styled-components";
import Gravatar from "react-gravatar";
import Avatar from "react-avatar";

const Card: any = styled.div`
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
  border: 1px solid var(--gray-100);
  background: var(--white);
  border-radius: 5px;
  width: 100%;
`;

Card.Head = styled.div`
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
`;

Card.Title = styled.p`
  color: var(--gray-700);
  font-size: 1.125rem;
`;

Card.Body = styled.div`
  padding: 10px;
`;

/* Card table */
Card.Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

Card.Table.Head = styled.thead``;

Card.Table.Head.Row = styled.tr`
  background: var(--gray-50);
`;

Card.Table.Head.Cell = styled.th`
  color: var(--gray-700);
  text-align: left;
  font-size: 14px;
  padding: 8px;
`;

Card.Table.Body = styled.tbody``;

Card.Table.Body.Row = styled.tr`
  &:last-child {
    td {
      border-bottom: 0px;
    }
  }
`;

Card.Table.Body.Cell = styled.td`
  border-bottom: 1px solid var(--gray-100);
  padding: 8px;

  .flex {
    align-items: center;
    display: flex;

    &.flex-end {
      justify-content: flex-end;
    }
  }
`;

Card.Table.Avatar = styled(Avatar)`
  border-radius: 7px;
  margin-right: 7px;
`;

Card.Table.Gravatar = styled(Gravatar)`
  border-radius: 7px;
  margin-right: 7px;
`;

export default Card;
