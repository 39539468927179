import React from "react";
import { Editor, Form, Input, Submit } from "@startaideia/react-forms";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import { Removable, Protected, useModal } from "app/shared";
import { versionService } from "services";
import { versionsActions } from "store";

function Modal({ version, platform, pushVersion, popVersion }) {
  const { closeModal } = useModal();

  /**
   * Handle form submit
   *
   */
  async function handleSubmit(versionData) {
    try {
      const versionResult = await versionService.save(versionData, version?.id);
      pushVersion(versionResult);
      toast("Versão salva com sucesso!", {
        type: toast.TYPE.SUCCESS,
      });
      closeModal();
    } catch ({ message }) {
      toast(message, {
        type: toast.TYPE.ERROR,
      });
    }
  }

  /**
   * Handle version remove
   *
   */
  async function handleRemove() {
    try {
      await versionService.destroy(version?.id);
      toast("Versão salva com sucesso!", {
        type: toast.TYPE.SUCCESS,
      });
      popVersion();
      closeModal();
    } catch ({ message }) {
      toast(message, {
        type: toast.TYPE.ERROR,
      });
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValue={version}
      className="px-4 pb-4 pt-0"
    >
      <Input.Text
        name="title"
        label="Versão"
        placeholder="Versão 1"
        isRequired
      />
      <Editor
        name="description"
        label="Meta"
        props={{ rows: 5 }}
        placeholder="Realizar todo o processo de onboarding de novos usuários"
      />
      <Input.Hidden name="platform_id" initialValue={platform.id} />
      <Protected resource="versions" action="destroy">
        {version?.id && (
          <Form.Col xs={12} className="py-3">
            <Removable onConfirm={handleRemove} value="Remover item" />
          </Form.Col>
        )}
      </Protected>
      <Form.Footer>
        <Submit>Salvar versão</Submit>
      </Form.Footer>
      <Protected resource="versions" action="update" elseIf>
        <Input.Hidden name="authorization" isRequired />
      </Protected>
    </Form>
  );
}

function mapStateToProps({ platforms }, { version }) {
  return {
    platform: platforms.platform,
    version: {
      ...version,
      description: version?.description || "",
    },
  };
}

function mapDispatchToProps(dispatch, { version }) {
  return {
    pushVersion: (version) => dispatch(versionsActions.pushVersion(version)),
    popVersion: () => dispatch(versionsActions.popVersion(version)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal) as any;
