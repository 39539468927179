import React, { useEffect } from "react";
import { GoPlus } from "react-icons/go";
import { FaCog } from "react-icons/fa";
import { connect } from "react-redux";

import { Container, Button, Nav } from "./styles";
import { Protected, useModal } from "app/shared";
import { versionsActions } from "store";
import Modal from "../Modal";

function List({ versions, selected, toggleVersion, loadVersions }) {
  const { openModal } = useModal();

  /**
   * Open version modal
   *
   */
  function handleModal(version = null) {
    return function () {
      openModal(<Modal version={version} />);
    };
  }

  useEffect(
    function () {
      loadVersions();
    },
    [loadVersions]
  );

  return (
    <Container>
      <Protected resource="versions" action="create">
        <Button onClick={handleModal()} title="Criar versão">
          <GoPlus />
        </Button>
      </Protected>
      <Nav>
        {versions.map((version) => (
          <Nav.Item
            key={version.id}
            className={`${
              selected.find((v) => v.id === version.id) && "actived"
            }`}
          >
            <Nav.Slot onClick={() => toggleVersion(version)}>
              {version.title}
            </Nav.Slot>
            <Nav.Slot onClick={handleModal(version)}>
              <FaCog />
            </Nav.Slot>
          </Nav.Item>
        ))}
      </Nav>
    </Container>
  );
}

function mapStateToProps({ platforms, versions }) {
  return {
    platform: platforms.platform,
    versions: versions.versions,
    selected: versions.selected,
  };
}

function mapDisptachToProps(dispatch, { platformId }) {
  return {
    loadVersions: () => dispatch(versionsActions.loadVersions(platformId)),
    toggleVersion: (version) =>
      dispatch(versionsActions.toggleVersion(version)),
  };
}

export default connect(mapStateToProps, mapDisptachToProps)(List) as any;
