import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import { Versions, Tasks } from "../../components";
import { platformsActions, stagesActions } from "store";
import { Container, Main } from "./styles";
import { Empty } from "app/shared";

function Detail({ epic, loadStages, loadPlatform }) {
  const { id } = useParams<any>();

  /* On component did moun */
  useEffect(
    function () {
      if (id) {
        loadPlatform(id);
      }
      loadStages();
    },
    [loadPlatform, loadStages, id]
  );

  return (
    <Container>
      <Versions.List platformId={id} />
      <Main>
        <Tasks.Header platformId={id} />
        {epic && <Tasks.List platformId={id} />}
        {!epic && <Empty />}
      </Main>
    </Container>
  );
}

function mapStateToProps({ tasks }) {
  return {
    epic: tasks.epic,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadPlatform: (id) => dispatch(platformsActions.loadPlatform(id)),
    loadStages: () => dispatch(stagesActions.loadStages()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Detail) as any;
