import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import { ResetPassword, ForgotPassword, Login } from "../screens";
import { GuestGuard } from "app/shared/guards";
import { Container } from "./styles";

function Routes() {
  const { path } = useRouteMatch();

  return (
    <Container>
      <Switch>
        <GuestGuard
          path={`${path}/forgot-password`}
          component={ForgotPassword}
          exact
        />
        <GuestGuard
          path={`${path}/reset-password`}
          component={ResetPassword}
          exact
        />
        <GuestGuard path={`${path}/login`} component={Login} exact />
      </Switch>
    </Container>
  );
}

export default Routes;
