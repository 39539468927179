import React from "react";
import ReactDOM from "react-dom";
import store from "./store";
import App from "./app";

/* Styling */
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import "./theme/index.scss";

/* Default form theme */
import "./theme/forms/default-theme.scss";

ReactDOM.render(
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>,
  document.getElementById("root")
);
