import styled from "styled-components";

export const Container = styled.div`
  overflow-y: scroll;
  margin-top: 15px;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-100);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--gray-300);
    cursor: pointer;
  }
`;

export const Card = styled.div`
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
`;
