import { Page } from "app/shared";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { UserCard, ActionsCard } from "../components";
import { Feed } from "../screens";

function Routes() {
  const { path } = useRouteMatch();

  return (
    <Page.Main>
      <Page.Aside>
        <UserCard />
        <ActionsCard />
      </Page.Aside>
      <Switch>
        <Route exact path={path} component={Feed} />
      </Switch>
    </Page.Main>
  );
}

export default Routes;
