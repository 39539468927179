import {
  SELECT_EPIC,
  LOAD_EPICS,
  PUSH_TASK,
  POP_TASK,
  LOADING_TASKS,
  SET_LINKS,
} from "../types";
import remove from "lodash/remove";
import { LOADING } from "store/platforms/types";

const INITIAL_STATE = {
  epic: null,
  epics: [],
  tasks: [],
  links: [],
  loading: false,
  loadingTasks: false,
};

function handleTaskMerge(state, task) {
  const key = task.task_id ? "tasks" : "epics";
  const index = state[key].indexOf(state[key].find((v) => v.id === task.id));

  if (index !== -1) {
    state[key][index] = task;
  } else {
    state[key].push(task);
  }

  return {
    ...state,
  };
}

function handleTaskRemove(state, task) {
  const key = task.task_id ? "tasks" : "epics";
  return {
    ...state,
    tasks: [],
    epic: state.epic.id === task.id ? null : state.epic,
    [key]: remove(state[key], (t) => t.id !== task.id),
  };
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_EPICS:
      return { ...state, tasks: [], epic: null, epics: action.payload };
    case SELECT_EPIC:
      return { ...state, ...action.payload };
    case PUSH_TASK:
      return handleTaskMerge({ ...state }, action.payload);
    case POP_TASK:
      return handleTaskRemove({ ...state }, action.payload);
    case LOADING:
      return { ...state, loading: action.payload };
    case LOADING_TASKS:
      return { ...state, loadingTasks: action.payload };
    case SET_LINKS:
      return { ...state, links: action.payload };
  }
  return { ...state };
}
