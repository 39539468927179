import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  @media (max-width: 992px) {
    position: initial;
  }
`;

export const DropdownButton: any = styled.div`
  transition: 0.25s;

  &.open {
    transform: rotate(180deg);
  }
`;

export const Dropdown: any = styled.ul`
  box-shadow: 1px 2px 2px var(--gray-100);
  border: 1px solid var(--gray-100);
  background: var(--white);
  position: absolute;
  border-radius: 5px;
  visibility: hidden;
  max-width: 400px;
  list-style: none;
  padding: 0px;
  width: 100vw;
  right: 0px;
  opacity: 0;
  z-index: 99;

  &.open {
    visibility: visible;
    opacity: 1;
  }
`;

Dropdown.Item = styled.li`
  align-items: center;
  display: flex;
  margin: 10px;
  padding: 5px;

  &:hover {
    background: var(--gray-50);
    border-radius: 5px;
    cursor: pointer;
  }
`;
Dropdown.Slot = styled.div`
  &.start {
    border-top: 2px solid transparent;
    background: var(--gray-50);
    justify-content: center;
    color: var(--gray-900);
    align-items: center;
    border-radius: 50%;
    transition: 0.25s;
    font-size: 20px;
    margin: 0px 5px;
    display: flex;
    height: 40px;
    width: 40px;
  }
  &.end {
    padding-left: 10px;
  }
`;
