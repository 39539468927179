import React from "react";
import { Form, Input, Submit } from "@startaideia/react-forms";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Container, Footer, Brand, Link } from "./styles";
import logo from "assets/images/logo.png";
import { authService } from "services";
import { Text } from "app/shared";

function Login() {
  const history = useHistory();

  async function handleSubmit(body) {
    try {
      await authService.login(body);
      return function () {
        history.push("/");
      };
    } catch ({ message }) {
      toast(message, {
        type: toast.TYPE.ERROR,
      });
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit} theme="starter-theme">
        <Form.Col className="pt-5">
          <Text value="Seja bem-vindo" size="18" weight="100" />
          <Text value="Faça login para continuar" size="36" className="py-3" />
        </Form.Col>

        <Input.Email isRequired props={{ autoComplete: "username" }} />
        <Input.Password
          isRequired
          props={{ autoComplete: "current-password" }}
        />
        <Form.Col xs={12}>
          <Link to="/auth/forgot-password">Esqueci minha senha</Link>
        </Form.Col>
        <Form.Footer>
          <Submit className="w-100">Entrar</Submit>
        </Form.Footer>
      </Form>
      <Footer>
        <Brand src={logo} />
      </Footer>
    </Container>
  );
}

export default Login;
