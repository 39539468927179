import React, { useEffect } from "react";
import { Form, Group, Input, Select, Submit } from "@startaideia/react-forms";
import { setConfiguration } from "react-grid-system";
import { RiTempColdLine } from "react-icons/ri";
import { FcBusinessman } from "react-icons/fc";
import { ImBlocked } from "react-icons/im";
import { FaFireAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import { Protected, TypeSelector } from "app/shared/components";
import { Removable } from "app/screens/App/shared";
import { setLinks } from "store/tasks/actions";
import { Editor, useModal } from "app/shared";
import { taskService } from "services";
import { tasksActions } from "store";
import { Flex } from "./styles";

setConfiguration({ gutterWidth: 10 });

function Modal({
  platformId,
  pushTask,
  setLinks,
  versions,
  personas,
  popTask,
  stages,
  medias,
  task,
  epic,
}) {
  const { closeModal } = useModal();

  /**
   * Create a persona
   *
   */
  async function handleSubmit(taskData) {
    try {
      const taskResult = await taskService.save(
        { ...taskData, medias },
        task?.id
      );
      toast("Tarefa salva com sucesso!", {
        type: toast.TYPE.SUCCESS,
      });
      return function () {
        pushTask(taskResult);
        closeModal();
      };
    } catch ({ message }) {
      toast(message, {
        type: toast.TYPE.ERROR,
      });
    }
  }

  /**
   * Remove a task
   *
   */
  async function handleRemove() {
    try {
      await taskService.destroy(task.id);
      toast("Tarefa removido com sucesso!", {
        type: toast.TYPE.SUCCESS,
      });
      popTask(task);
      closeModal();
    } catch ({ message }) {
      toast(message, {
        type: toast.TYPE.ERROR,
      });
    }
  }

  /* handle effect */
  useEffect(
    function () {
      setLinks();
    },
    [setLinks]
  );

  return (
    <Form onSubmit={handleSubmit} initialValue={task} className="p-2">
      <Form.Col xs={9}>
        <Input.Text
          name="title"
          label="Título"
          help="Dica: escreva o título da tarefa como uma ação do ponto de vista da persona que irá executar essa tarefa. Ex: Logar com o facebook."
          isRequired
        />
        <Editor name="description" label="Descrição" props={{ rows: 5 }} />
        <Group name="personas" label="Personas" multiple initialValue={[]}>
          <Flex>
            {personas?.map(function ({ id, title }) {
              return (
                <TypeSelector
                  key={id}
                  value={id}
                  label={title}
                  icon={<FcBusinessman />}
                  small
                />
              );
            })}
          </Flex>
        </Group>
        <Group isRequired name="requirement_level" label="Nível">
          <Flex>
            <TypeSelector
              value="must_have"
              label="Must Have"
              icon={<FaFireAlt />}
            />
            <TypeSelector
              value="could_have"
              label="Could Have"
              icon={<RiTempColdLine />}
            />
            <TypeSelector
              value="wont_have"
              label="Wont Have"
              icon={<ImBlocked />}
            />
          </Flex>
        </Group>
      </Form.Col>
      <Form.Col xs={3}>
        <Select name="stage_id" label="Estágio" isRequired>
          {stages.map(function (item, key) {
            return (
              <Select.Option key={key} value={item.id} label={item.title} />
            );
          })}
        </Select>
        <Select name="effort" label="Esforço" initialValue={1} isRequired>
          {[1, 2, 3, 5, 8, 12, 13, 21, 24, 34, 40, 55, 89].map(function (i) {
            return <Select.Option key={i} value={i} label={`${i} hora(s)`} />;
          })}
        </Select>
        <Select name="version_id" label="Versão">
          {versions.map(function (version) {
            return (
              <Select.Option
                key={version.id}
                value={version.id}
                label={version.title}
              />
            );
          })}
        </Select>

        <Input.Hidden name="platform_id" initialValue={platformId || ""} />
        <Protected resource="tasks" action="destroy">
          {task?.id && (
            <Removable
              className="mt-3"
              onConfirm={handleRemove}
              value="Remover item"
            />
          )}
        </Protected>
        <Input.Hidden name="task_id" initialValue={epic?.id || ""} />
      </Form.Col>

      <Form.Footer xs={12}>
        <Submit>Salvar</Submit>
      </Form.Footer>

      <Protected resource="tasks" action="update" elseIf>
        <Input.Hidden name="authorization" isRequired />
      </Protected>
    </Form>
  );
}

function mapStateToProps(
  { personas, tasks, stages, versions },
  { stageId, task, taskId }
) {
  return {
    personas: personas.personas,
    versions: versions.versions,
    stages: stages.stages,
    medias: tasks.links,
    epic: tasks.epic,
    task: {
      ...task,
      personas: task?.personas?.map((p) => p.id),
      task_id: task?.task_id || taskId || "",
      description: task?.description || "",
      stage_id: task?.stage_id || stageId || "",
    },
  };
}

function mapDisptachToProps(dispatch) {
  return {
    pushTask: (task) => dispatch(tasksActions.pushTask(task)),
    popTask: (task) => dispatch(tasksActions.popTask(task)),
    setLinks: () => dispatch(setLinks([])),
  };
}

export default connect(mapStateToProps, mapDisptachToProps)(Modal) as any;
