import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";

import { projectsActions } from "store";
import { Text } from "app/shared";
import { Group } from "./styles";

function ProjectCard({ loadProject, project }) {
  /* Computed completness */
  const completness = useMemo(
    function () {
      return project?.current_completness_percent || 0;
    },
    [project]
  );

  /* On component did update */
  useEffect(
    function () {
      loadProject();
    },
    [loadProject]
  );

  return (
    <Group>
      <Group.Body>
        <Group.Body.Slot>
          <Group.Body.Icon name={project?.title} size={50} />
        </Group.Body.Slot>
        <Group.Body.Slot>
          <Text value={project?.title} size={16} />
          <Text
            value={`Em desenvolvimento (${completness}%)`}
            size={10}
            className="mt-1 mb-1"
          />
        </Group.Body.Slot>
      </Group.Body>
    </Group>
  );
}

function mapStateToProps({ projects }) {
  return {
    project: projects.project,
  };
}

function mapDispatchToProps(dispatch, { project_id }) {
  return {
    loadProject: () => dispatch(projectsActions.loadProject(project_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCard) as any;
