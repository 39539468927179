import styled from "styled-components";

export const Container = styled.div`
  min-height: calc(100vh - 50px);
  display: inline-block;
  padding-top: 250px;
`;

export const Button = styled.button`
  border: 1px solid var(--gray-200);
  background: var(--white);
  color: var(--gray-500);
  border-radius: 50%;
  transition: 0.25s;
  outline: none;
  height: 30px;
  margin: 5px;
  width: 30px;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  &:active {
    transform: scale(1);
  }
`;

export const Nav: any = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 600px;
  list-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

Nav.Item = styled.li`
  border: 1px solid var(--gray-200);
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  background: var(--white);
  text-orientation: mixed;
  color: var(--gray-500);
  border-radius: 20px;
  text-align: center;
  font-weight: bold;
  line-height: 30px;
  transition: 0.25s;
  overflow: hidden;
  font-size: 12px;
  display: flex;
  width: 30px;
  margin: 5px;

  &.actived:hover,
  &.actived {
    border: 1px solid var(--pink-500);
    background: var(--pink-400);
    color: var(--white);
  }
`;

Nav.Slot = styled.div`
  padding: 10px 0px;

  &:hover {
    background: var(--pink-400);
    color: var(--white);
  }
`;
