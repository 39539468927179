import React, { useRef, useCallback, useEffect, useState } from "react";

import {
  BsCaretDownFill,
  BsFillKanbanFill,
  BsPeopleFill,
} from "react-icons/bs";
import { useRouteMatch } from "react-router-dom";
import { GoPlus, GoBell } from "react-icons/go";
import { MdMenu } from "react-icons/md";
import { HiHome } from "react-icons/hi";
import { connect } from "react-redux";

import { Brand, Group, Tabs, Aside, Container, User, Menu } from "./styles";
import NotificationsDropDown from "./NotificationsDropDown";
import { Protected, Page, useNavigate } from "app/shared";
import ActionsDropDown from "./ActionsDropDown";
import UserDropDown from "./UserDropDown";
import logo from "assets/images/logo.png";

function Navbar({ user }) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { navigate } = useNavigate();
  const { path } = useRouteMatch();
  const domRef = useRef<any>(null);

  /* change if a menu item is actived */
  const isActived = useCallback(
    function (url: string) {
      return path.toLowerCase().indexOf(url) !== -1;
    },
    [path]
  );

  /* Toggle navbar on mobile */
  const handleClickOutside = useCallback(
    function (e) {
      if (!domRef.current || !expanded) return;

      if (!e.target.contains(domRef.current)) {
        setExpanded(false);
      }
    },
    [domRef, expanded]
  );

  /* Register click outside */
  useEffect(
    function () {
      document.addEventListener("mousedown", handleClickOutside);
      return function () {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    },
    [handleClickOutside]
  );

  return (
    <Container>
      <Page.Main justifyContent="space-between" display="flex">
        <Aside>
          <Menu onMouseDown={() => setExpanded(!expanded)}>
            <MdMenu />
          </Menu>
          <Brand src={logo} />
        </Aside>
        <Tabs className={expanded && "__expanded"} ref={domRef}>
          <Tabs.Tab
            className={isActived("/feed") && "actived"}
            onClick={navigate("/feed")}
            title="Inicio"
          >
            <HiHome />
            <Tabs.Title>Início</Tabs.Title>
          </Tabs.Tab>
          <Tabs.Tab
            className={isActived("/projects") && "actived"}
            onClick={navigate("/projects")}
            title="Projetos"
          >
            <BsFillKanbanFill />
            <Tabs.Title>Projetos</Tabs.Title>
          </Tabs.Tab>
          <Protected resource="members" action="read">
            <Tabs.Tab
              className={isActived("/teams") && "actived"}
              onClick={navigate("/teams")}
              title="Time"
            >
              <BsPeopleFill />
              <Tabs.Title>Time</Tabs.Title>
            </Tabs.Tab>
          </Protected>
        </Tabs>
        <Group>
          <User title="Abrir perfil do usuário">
            {user && <User.Avatar email={user?.email} size={35} />}
            <span>{user?.first_name}</span>
          </User>
          <Protected>
            <ActionsDropDown>
              <Group.Item>
                <GoPlus />
              </Group.Item>
            </ActionsDropDown>
          </Protected>
          <NotificationsDropDown>
            <Group.Item>
              <GoBell />
            </Group.Item>
          </NotificationsDropDown>
          <UserDropDown>
            <Group.Item>
              <BsCaretDownFill />
            </Group.Item>
          </UserDropDown>
        </Group>
      </Page.Main>
    </Container>
  );
}

function mapStateToProps({ app }) {
  return {
    user: app.user,
  };
}

export default connect(mapStateToProps)(Navbar) as any;
