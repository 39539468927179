import React, { useEffect } from "react";
import { MdClose } from "react-icons/md";

import { Container, Card, Close } from "./styles";
import { useModal } from "app/shared/hooks";

function Modal() {
  const { isOpen, closeModal, modalContent, modalSize } = useModal();

  /**
   * Close the modal if the user click on the backdrop
   *
   */
  function handleBackDropClick(e) {
    closeModal();
  }

  /**
   * Prevent the modal to close if the click is in the body
   *
   */
  function handleModalBodyClick(e) {
    e.stopPropagation();
  }

  useEffect(
    function () {
      document.body.style.overflowY = isOpen ? "hidden" : "scroll";
    },
    [isOpen]
  );

  return (
    <Container className={isOpen && `is-visible`}>
      <Card
        modalSize={modalSize}
        onClick={handleModalBodyClick}
        className={isOpen && `is-visible`}
      >
        <Card.Header>
          <Close onClick={handleBackDropClick}>
            <MdClose />
          </Close>
        </Card.Header>
        <Card.Body>{modalContent}</Card.Body>
      </Card>
    </Container>
  );
}

export default Modal;
