import styled from "styled-components";
import Gravatar from "react-gravatar";

export const Container = styled.div`
  border-bottom: 1px solid var(--gray-100);
  background: var(--white);
  position: relative;
  padding: 0px 15px;
  position: fixed;
  display: flex;
  height: 50px;
  z-index: 9;
  width: 100%;
`;

export const Aside = styled.div`
  align-items: center;
  max-width: 350px;
  display: flex;
  flex-grow: 1;
`;

export const Brand = styled.img`
  height: 35px;
  width: 35px;
`;

export const Tabs: any = styled.ul`
  list-style: none;
  display: flex;
  flex-grow: 1;
  padding: 0;
  margin: 0;

  @media (max-width: 992px) {
    visibility: hidden;
    border-bottom: 1px solid var(--gray-100);
    background: var(--white);
    position: absolute;
    transition: 0.5s;
    margin-top: 1px;
    display: block;
    padding: 20px;
    width: 100vw;
    height: auto;
    opacity: 0;
    left: 0;
    top: 0;

    &.__expanded {
      visibility: visible;
      opacity: 1;
      top: 100%;
    }
  }
`;

Tabs.Tab = styled.li`
  border-top: 2px solid transparent;
  color: var(--gray-500);
  align-items: center;
  padding: 0px 10px;
  transition: 0.25s;
  font-size: 28px;
  display: flex;

  &.actived,
  &:hover {
    color: var(--pink-400);
    cursor: pointer;
  }

  @media (max-width: 992px) {
    padding: 10px 0px;
  }
`;

Tabs.Title = styled.div`
  padding: 0px 10px;
  font-weight: bold;
  font-size: 14px;

  @media (max-width: 992px) {
    padding: 0px 20px;
  }
`;

export const Group: any = styled.ul`
  justify-content: flex-end;
  justify-self: flex-end;
  align-items: center;
  max-width: 350px;
  list-style: none;
  display: flex;
  flex-grow: 1;
  padding: 0;
  margin: 0;
`;

Group.Item = styled.li`
  border-top: 2px solid transparent;
  background: var(--gray-50);
  justify-content: center;
  color: var(--gray-900);
  align-items: center;
  border-radius: 50%;
  transition: 0.25s;
  font-size: 20px;
  margin: 0px 5px;
  display: flex;
  height: 35px;
  width: 35px;

  &:hover {
    background: var(--gray-100);
    cursor: pointer;
  }
`;

export const Menu = styled.div`
  color: var(--gray-700);
  justify-items: center;
  align-items: center;
  font-size: 24px;
  display: flex;
  margin: 10px;

  @media (min-width: 992px) {
    display: none;
  }
`;

export const User: any = styled.li`
  padding: 0px 10px 0px 0px;
  border-radius: 20px;
  align-items: center;
  font-size: 12px;
  display: flex;

  &:hover {
    background: var(--gray-50);
    cursor: pointer;
  }
`;

User.Avatar = styled(Gravatar)`
  border-radius: 50%;
  margin-right: 10px;
`;
