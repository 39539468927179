import {
  LOADING,
  LOAD_REQUISITES,
  POP_REQUISITE,
  PUSH_REQUISITE,
} from "../types";
import { requisiteService } from "services";

/**
 * Load all requisites from a platform
 *
 */
export function loadRequisites(platformId) {
  return async function (dispatch) {
    dispatch({
      type: LOADING,
      payload: true,
    });
    const versions = await requisiteService.list(
      `?filter[platform_id]=${platformId}`
    );
    dispatch({
      type: LOAD_REQUISITES,
      payload: [...versions],
    });
    dispatch({
      type: LOADING,
      payload: false,
    });
  };
}

/**
 * Push requisite into array
 *
 */
export function pushRequisite(requisite) {
  return {
    type: PUSH_REQUISITE,
    payload: requisite,
  };
}

/**
 * Pop requisite from array
 *
 */
export function popRequisite(requisite) {
  return {
    type: POP_REQUISITE,
    payload: requisite,
  };
}
