import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { FaCogs } from "react-icons/fa";
import { connect } from "react-redux";

import { SearchInput, Card, Button, Protected, useNavigate } from "app/shared";
import { projectsActions } from "store";
import { Link } from "./styles";

function List({ projects, loading, loadProjects }) {
  const [filter, setFilter] = useState<string>("");
  const { navigate } = useNavigate();

  /* Component did mount */
  useEffect(
    function () {
      loadProjects();
    },
    [loadProjects]
  );

  return (
    <Card>
      <Card.Head>
        <Card.Title>Projetos</Card.Title>
        <SearchInput onInput={setFilter} size="200px" />
      </Card.Head>

      <Card.Body>
        <Protected resource="members" action="create">
          <Button onClick={navigate("/projects/create")}>Criar projeto</Button>
        </Protected>
      </Card.Body>

      {loading && (
        <div className="text-center py-5">
          <Card.Body>
            <Loader type="TailSpin" color="var(--gray-500)" />
          </Card.Body>
        </div>
      )}

      {!loading && (
        <Card.Table>
          <Card.Table.Head>
            <Card.Table.Head.Row>
              <Card.Table.Head.Cell>Projeto</Card.Table.Head.Cell>
              <Protected resource="projects" action="update">
                <Card.Table.Head.Cell />
              </Protected>
            </Card.Table.Head.Row>
          </Card.Table.Head>
          <Card.Table.Body>
            {projects
              .filter((p) => p.title.toLowerCase().indexOf(filter) !== -1)
              .map(function ({ id, title }) {
                return (
                  <Card.Table.Body.Row key={id}>
                    <Card.Table.Body.Cell>
                      <Link to={`/projects/${id}`}>
                        <Card.Table.Avatar name={title} size={32} />
                        {title}
                      </Link>
                    </Card.Table.Body.Cell>
                    <Card.Table.Body.Cell>
                      <div className="flex flex-end pr-3">
                        <Protected resource="projects" action="update">
                          <FaCogs
                            onClick={navigate(`/projects/create/${id}`)}
                            className="hoverble"
                          />
                        </Protected>
                      </div>
                    </Card.Table.Body.Cell>
                  </Card.Table.Body.Row>
                );
              })}
          </Card.Table.Body>
        </Card.Table>
      )}
    </Card>
  );
}

function mapStateToProps({ projects }) {
  return {
    projects: projects.projects,
    loading: projects.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadProjects: () => dispatch(projectsActions.loadProjects()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
