import React from "react";
import { RiTempColdLine } from "react-icons/ri";
import { FaFireAlt } from "react-icons/fa";
import { ImBlocked } from "react-icons/im";
import Loader from "react-loader-spinner";
import { GoPlus } from "react-icons/go";
import { connect } from "react-redux";

import { Protected, Text, useModal } from "app/shared";
import { ScrollContainer, Group } from "./styles";
import Modal from "../Modal";

function TaskList({ epic, loading, tasks, stages, platformId, versions }) {
  const { openModal } = useModal();

  /**
   * Handle modal selection
   *
   */
  function handleModal(task = null, stageId = null) {
    return function () {
      openModal(
        <Modal
          stageId={stageId}
          platformId={platformId}
          taskId={epic.id}
          task={task}
        />
      );
    };
  }

  /**
   * Check if the task is visible
   *
   */
  function visible(id) {
    if (versions.length === 0) return true;
    if (!id) return false;
    return versions.find((v) => v.id === id);
  }

  if (loading) {
    return (
      <div className="text-center">
        <Loader width="50" type="TailSpin" color="var(--gray-500)" />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="p-2">
        <Text value={`#${epic.id} ${epic.title}`} size={18} display="block" />
      </div>
      <ScrollContainer className="mt-3">
        {stages.map(function (item) {
          return (
            <Group key={item.title}>
              <Group.Header>
                <Text value={item.title} size="16" weight="bold" />
                <Protected resource="tasks" action="create">
                  <GoPlus
                    className="mr-2"
                    onClick={handleModal(null, item.id)}
                  />
                </Protected>
              </Group.Header>
              <Group.Body>
                {tasks
                  .filter(({ stage_id }) => stage_id === item.id)
                  .filter(({ version_id }) => visible(version_id))
                  .map(function (task) {
                    return (
                      <Group.Item key={task.id} onClick={handleModal(task)}>
                        {task.version && (
                          <Group.Label>{task.version.title}</Group.Label>
                        )}
                        <Text
                          value={`#${task.id} - ${task.effort} hora(s)`}
                          size={10}
                          display="block"
                          className="pt-2"
                        />
                        <Text
                          value={task.title}
                          display="block"
                          className="py-2"
                        />

                        <Group.Item.Footer>
                          {task.requirement_level === "could_have" && (
                            <RiTempColdLine />
                          )}
                          {task.requirement_level === "must_have" && (
                            <FaFireAlt />
                          )}
                          {task.requirement_level === "wont_have" && (
                            <ImBlocked />
                          )}
                        </Group.Item.Footer>
                      </Group.Item>
                    );
                  })}
              </Group.Body>
              <Protected resource="tasks" action="create">
                <Group.Footer onClick={handleModal(null, item.id)}>
                  <GoPlus className="mr-2" />
                  <Text value="Adicionar pacote" />
                </Group.Footer>
              </Protected>
            </Group>
          );
        })}
      </ScrollContainer>
    </React.Fragment>
  );
}

function mapStateToProps({ tasks, stages, versions }) {
  return {
    epic: tasks.epic,
    tasks: tasks.tasks,
    stages: stages.stages,
    versions: versions.selected,
    loading: tasks.loadingTasks,
  };
}

function mapDisptachToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDisptachToProps)(TaskList) as any;
