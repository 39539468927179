import React, { useState } from "react";
import { Form, Input, Select, Submit } from "@startaideia/react-forms";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Removable, Protected, Card, useNavigate, useFetch } from "app/shared";
import { memberService } from "services";
import { Container } from "./styles";

const titles = [
  "Administrador",
  "Desenvolvedor",
  "Designer",
  "Gerente",
  "Analista",
];

function Create() {
  const { id } = useParams<any>();
  const { data: member } = useFetch(`/members/${id}`, !!id);
  const [loading, setLoading] = useState(false);
  const { navigate } = useNavigate();

  /* Handle member remove */
  async function handleRemove() {
    try {
      await memberService.destroy(member?.id);
      setLoading(true);
      toast("Membro removido com sucesso", {
        type: toast.TYPE.SUCCESS,
      });
      setLoading(false);
      navigate(`/teams`)();
    } catch ({ message }) {
      toast(message, {
        type: toast.TYPE.ERROR,
      });
    }
  }

  /* Handle form submit */
  async function handleSubmit(memberData) {
    try {
      setLoading(true);
      await memberService.save(memberData, member?.id);
      setLoading(false);
      toast("Membro salvo com sucesso", {
        type: toast.TYPE.SUCCESS,
      });
      navigate(`/teams`)();
    } catch ({ message }) {
      setLoading(false);
      toast(message, {
        type: toast.TYPE.ERROR,
      });
    }
  }

  return (
    <Container>
      <Card>
        <Form onSubmit={handleSubmit} initialValue={member}>
          <Form.Col>
            <Card.Head>
              <Card.Title>Membro</Card.Title>
              <Submit disabled={loading}>Salvar membro</Submit>
            </Card.Head>
            <Card.Body className="pb-5">
              <Form.Row>
                <Input.Text
                  name="first_name"
                  label="Nome"
                  xs={6}
                  isRequired
                  props={{
                    readOnly: member?.id,
                  }}
                />
                <Input.Text
                  name="last_name"
                  label="Sobrenome"
                  xs={6}
                  isRequired
                  props={{
                    readOnly: member?.id,
                  }}
                />
              </Form.Row>

              <Input.Email
                isRequired
                props={{
                  readOnly: member?.id,
                }}
              />

              <Select isRequired name="title" label="Nível de acesso">
                {titles.map(function (item) {
                  return <Select.Option value={item} label={item} key={item} />;
                })}
              </Select>
            </Card.Body>
          </Form.Col>
        </Form>
      </Card>
      <Protected resource="members" action="destroy">
        {member?.id && (
          <div className="pt-3">
            <Removable onConfirm={handleRemove} value="Remover item" />
          </div>
        )}
      </Protected>
    </Container>
  );
}

export default Create;
