import styled from "styled-components";
import ReactAvatar from "react-avatar";

export const Container = styled.div``;

export const Group: any = styled.div`
  flex-direction: column;
  border-radius: 5px;
  display: flex;
`;

Group.Title = styled.div`
  padding-bottom: 0px;
  transition: 0.25s;
  padding: 5px 10px;

  &:hover {
    background: var(--gray-50);
    cursor: pointer;
  }
`;

Group.Title.Icon = styled(ReactAvatar)`
  border-radius: 20px;
  margin-right: 10px;
`;

Group.Body = styled.div`
  display: flex;
`;

Group.Body.Slot = styled.div`
  justify-content: center;
  flex-direction: column;
  display: flex;
  padding: 10px;
`;

Group.Body.Icon = styled(ReactAvatar)`
  border-radius: 5px;
`;

export const Card: any = styled.div`
  box-shadow: 1px 2px 2px var(--gray-100);
  border: 1px solid var(--gray-100);
  flex-direction: column;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
`;

Card.Title = styled.div`
  padding: 10px;
`;

Card.Body = styled.div`
  padding: 10px;
`;

export const List: any = styled.ul`
  list-style: none;
  flex-wrap: wrap;
  display: flex;
  padding: 0px;
  margin: 0px;
`;

List.Item = styled.li`
  box-shadow: 1px 2px 2px var(--gray-100);
  border: 1px solid var(--gray-100);
  background: var(--gray-50);
  justify-content: center;
  color: var(--gray-600);
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  transition: 0.25s;
  font-size: 20px;
  min-width: 50px;
  height: 50px;
  margin: 5px;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }

  &:active {
    transform: scale(1);
  }
`;
