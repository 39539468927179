import styled from "styled-components";

export const Container: any = styled.div`
  max-width: ${({ size }: any) => size || "100%"};
  border: 1px solid var(--gray-200);
  background: var(--gray-50);
  color: var(--gray-300);
  display: inline-flex;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  height: 32px;
  width: 100%;
`;

export const Input = styled.input`
  background: transparent;
  display: inline-block;
  width: 100%;
  border: 0;

  &:focus {
    outline: none;
  }
`;

export const Icon = styled.div`
  justify-content: center;
  align-items: center;
  font-size: 16px;
  display: flex;
  height: 32px;
  width: 40px;
`;
