import React, { useCallback, useEffect } from "react";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import ptBrStrings from "react-timeago/lib/language-strings/pt-br";
import { FaCogs, FaFireAlt } from "react-icons/fa";
import { HiDocumentReport } from "react-icons/hi";
import { RiTempColdLine } from "react-icons/ri";
import { FiRefreshCw } from "react-icons/fi";
import { ImBlocked } from "react-icons/im";
import Loader from "react-loader-spinner";
import { GoPlus } from "react-icons/go";
import { connect } from "react-redux";
import TimeAgo from "react-timeago";

import { ScrollContainer, Container, List, Flex } from "./styles";
import { Button, Protected, Text, useModal } from "app/shared";
import ReportModal from "../../ReportModal";
import { tasksActions } from "store";
import Modal from "../Modal";

const formatter = buildFormatter(ptBrStrings);

function EpicList({ epics, loading, loadEpics, selectEpic, platformId }) {
  const { openModal } = useModal();

  /* Handle modal opening */
  const handleOpenModal = useCallback(
    function (task = {}) {
      return function () {
        openModal(<Modal task={task} platformId={platformId} />);
      };
    },
    [openModal, platformId]
  );

  /* Handle modal opening */
  function handleReportModal() {
    openModal(<ReportModal />);
  }

  /* Handle epic selection */
  function handleSelectEpic(epic) {
    return function () {
      selectEpic(epic);
    };
  }

  useEffect(
    function () {
      loadEpics();
    },
    [loadEpics]
  );

  return (
    <Container>
      <Flex>
        <Protected resource="tasks" action="create">
          <Button onClick={handleOpenModal()}>
            <GoPlus className="mr-2" />
            Criar épico
          </Button>
        </Protected>
        <div>
          <Button
            title="Atualizar dashboard"
            onClick={() => loadEpics()}
            className="mr-1"
          >
            <FiRefreshCw />
          </Button>
          <Button
            onClick={handleReportModal}
            title="Relatórios"
            className="mr-1"
          >
            <HiDocumentReport />
          </Button>
        </div>
      </Flex>

      <ScrollContainer className="mt-3">
        {loading ? (
          <div className="text-center">
            <Loader width="50" type="TailSpin" color="var(--gray-500)" />
          </div>
        ) : (
          <List>
            {epics.map(function (epic) {
              return (
                <List.Item key={epic.id} onClick={handleSelectEpic(epic)}>
                  <List.Item.Body>
                    <Text
                      value={
                        <TimeAgo date={epic.created_at} formatter={formatter} />
                      }
                      size="8"
                      display="block"
                    />
                    <Text
                      className="my-2 b-1"
                      size={11}
                      value={epic.title}
                      display="block"
                    />
                    <Text
                      value={`${epic.current_completness_percent}% completo`}
                      size="10"
                      display="block"
                    />
                  </List.Item.Body>
                  <List.Item.Footer>
                    {epic.requirement_level === "could_have" && (
                      <RiTempColdLine />
                    )}
                    {epic.requirement_level === "must_have" && <FaFireAlt />}
                    {epic.requirement_level === "wont_have" && <ImBlocked />}
                    <List.Item.Icon onClick={handleOpenModal(epic)}>
                      <FaCogs title="Editar" />
                    </List.Item.Icon>
                  </List.Item.Footer>
                </List.Item>
              );
            })}
          </List>
        )}
      </ScrollContainer>
    </Container>
  );
}

function mapStateToProps({ tasks }) {
  return {
    ...tasks,
  };
}

function mapDisptachToProps(dispatch, { platformId }) {
  return {
    loadEpics: () => dispatch(tasksActions.loadEpics(platformId)),
    selectEpic: (epic) => dispatch(tasksActions.selectEpic(epic, platformId)),
  };
}

export default connect(mapStateToProps, mapDisptachToProps)(EpicList) as any;
