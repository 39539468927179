import {
  LOAD_VERSIONS,
  POP_VERSION,
  PUSH_VERSION,
  TOGGLE_VERSION,
} from "../types";
import remove from "lodash/remove";
import { LOADING } from "store/requisites/types";

const INITIAL_STATE = {
  selected: [],
  versions: [],
  loading: false,
};

function handleVersionMerge(state, version) {
  const index = state.versions.indexOf(
    state.versions.find((v) => v.id === version.id)
  );

  if (index !== -1) {
    state.versions[index] = version;
  } else {
    state.versions.push(version);
  }

  return {
    ...state,
  };
}

function handleVersionRemove(state, version) {
  return {
    ...state,
    versions: remove(state.versions, (t) => t.id !== version.id),
  };
}

function handleVersionToggle(state, version) {
  const result = state.selected.find((s) => version.id === s.id);
  if (result) {
    return {
      ...state,
      selected: remove(state.selected, (s) => s.id !== version.id),
    };
  }
  return {
    ...state,
    selected: [...state.selected, version],
  };
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_VERSIONS:
      return { ...state, versions: action.payload };
    case PUSH_VERSION:
      return handleVersionMerge({ ...state }, action.payload);
    case POP_VERSION:
      return handleVersionRemove({ ...state }, action.payload);
    case TOGGLE_VERSION:
      return handleVersionToggle({ ...state }, action.payload);
    case LOADING:
      return { ...state, loading: action.payload };
  }
  return { ...state };
}
