import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import { FaCogs } from "react-icons/fa";
import { connect } from "react-redux";

import { Button, Card, Protected, SearchInput, useNavigate } from "app/shared";
import { personasActions } from "store";

function List({ personas, loadPersonas, loading }) {
  const [filter, setFilter] = useState<string>("");
  const { project_id } = useParams<any>();
  const { navigate } = useNavigate();

  /* load personas by project */
  useEffect(
    function () {
      loadPersonas(project_id);
    },
    [project_id, loadPersonas]
  );

  return (
    <Card>
      <Card.Head>
        <Card.Title>Personas</Card.Title>
        <SearchInput onInput={setFilter} size="200px" />
      </Card.Head>

      <Card.Body>
        <Protected resource="personas" action="create">
          <Button onClick={navigate(`/projects/${project_id}/personas/form`)}>
            Criar persona
          </Button>
        </Protected>
      </Card.Body>

      {loading && (
        <div className="text-center py-5">
          <Card.Body>
            <Loader type="TailSpin" color="var(--gray-500)" />
          </Card.Body>
        </div>
      )}

      {!loading && (
        <Card.Table>
          <Card.Table.Head>
            <Card.Table.Head.Row>
              <Card.Table.Head.Cell>Personas</Card.Table.Head.Cell>
              <Protected resource="personas" action="update">
                <Card.Table.Head.Cell />
              </Protected>
            </Card.Table.Head.Row>
          </Card.Table.Head>
          <Card.Table.Body>
            {personas
              .filter((p) => p.title.toLowerCase().indexOf(filter) !== -1)
              .map(function ({ id, title }) {
                return (
                  <Card.Table.Body.Row key={id}>
                    <Card.Table.Body.Cell>
                      <Card.Table.Avatar name={title} size={32} />
                      {title}
                    </Card.Table.Body.Cell>
                    <Card.Table.Body.Cell>
                      <div className="flex flex-end">
                        <Protected resource="projects" action="update">
                          <FaCogs
                            onClick={navigate(
                              `/projects/${project_id}/personas/${id}/edit`
                            )}
                            className="hoverble mr-2"
                          />
                        </Protected>
                      </div>
                    </Card.Table.Body.Cell>
                  </Card.Table.Body.Row>
                );
              })}
          </Card.Table.Body>
        </Card.Table>
      )}
    </Card>
  );
}

function mapStateToProps({ personas }) {
  return {
    personas: personas.personas,
    loading: personas.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadPersonas: (project_id) =>
      dispatch(personasActions.loadPersonas(project_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List) as any;
