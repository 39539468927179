import ResourceService from "../resourceService";

export class PersonaService extends ResourceService {
  /**
   * Resource route
   *
   */
  public route: string = "personas";
}

export default new PersonaService();
