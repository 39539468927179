import React, { useMemo, useState } from "react";
import Loader from "react-loader-spinner";
import { FaCogs } from "react-icons/fa";

import {
  Empty,
  Protected,
  SearchInput,
  useFetch,
  useNavigate,
} from "app/shared";
import { Button, Card, Text } from "app/shared";

function List() {
  const [filter, setFilter] = useState("");
  const { data } = useFetch("/members");
  const { navigate } = useNavigate();

  /* computed members list */
  const computedData = useMemo(
    function () {
      return data?.filter(function ({ first_name, last_name, email, title }) {
        return (
          first_name.toLowerCase().indexOf(filter) !== -1 ||
          last_name.toLowerCase().indexOf(filter) !== -1 ||
          email.toLowerCase().indexOf(filter) !== -1 ||
          title.toLowerCase().indexOf(filter) !== -1
        );
      });
    },
    [data, filter]
  );

  return (
    <Card>
      <Card.Head>
        <Card.Title>Profissionais</Card.Title>
        <SearchInput onInput={setFilter} size="200px" />
      </Card.Head>

      <Card.Body>
        <Protected resource="members" action="create">
          <Button onClick={navigate("/teams/create")}>Adicionar membro</Button>
        </Protected>
      </Card.Body>

      {!computedData && (
        <Card.Body>
          <div className="text-center py-5">
            <Loader type="TailSpin" color="var(--gray-500)" />
          </div>
        </Card.Body>
      )}

      {computedData && computedData.length === 0 && (
        <Card.Body>
          <Empty />
        </Card.Body>
      )}

      {computedData && computedData.length > 0 && (
        <Card.Table>
          <Card.Table.Head>
            <Card.Table.Head.Row>
              <Card.Table.Head.Cell>Usuário</Card.Table.Head.Cell>
              <Card.Table.Head.Cell>Papel</Card.Table.Head.Cell>
              <Card.Table.Head.Cell />
            </Card.Table.Head.Row>
          </Card.Table.Head>
          <Card.Table.Body>
            {computedData?.map(function (user) {
              return (
                <Card.Table.Body.Row key={user.id}>
                  <Card.Table.Body.Cell>
                    <div className="flex">
                      <Card.Table.Gravatar email={user.email} size={32} />
                      <div>
                        <div className="mb-1">
                          {user.first_name} {user.last_name}
                        </div>
                        <Text value={user.email} display="block" />
                      </div>
                    </div>
                  </Card.Table.Body.Cell>
                  <Card.Table.Body.Cell>
                    <Text value={user.title} display="block" />
                  </Card.Table.Body.Cell>
                  <Card.Table.Body.Cell>
                    <Protected resource="members" action="destroy">
                      <FaCogs
                        onClick={navigate(`/teams/create/${user.id}`)}
                        className="hoverble"
                      />
                    </Protected>
                  </Card.Table.Body.Cell>
                </Card.Table.Body.Row>
              );
            })}
          </Card.Table.Body>
        </Card.Table>
      )}
    </Card>
  );
}

export default List;
