import React, { useMemo } from "react";
import { connect } from "react-redux";

function Protected({ user, elseIf = false, children }) {
  const isAllowed = useMemo(
    function () {
      const result = user && user.is_admin;
      return elseIf ? !result : result;
    },
    [user, elseIf]
  );

  return <>{isAllowed ? children : null}</>;
}

function mapStateToProps({ app }) {
  return app;
}

export default connect(mapStateToProps)(Protected) as any;
