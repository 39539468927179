import React, { useMemo } from "react";
import { Submit, Form, Input } from "@startaideia/react-forms";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Editor, Protected, useFetch, useNavigate } from "app/shared";
import { Removable } from "app/screens/App/shared";
import { projectService } from "services";
import { Card } from "app/shared";

function Create() {
  const { id } = useParams<any>();
  const { data } = useFetch(`/projects/${id}`, !!id);
  const { navigate } = useNavigate();

  /* Handle form submit */
  async function handleSubmit(body) {
    try {
      const { id } = await projectService.save(body, data?.id);
      return function () {
        navigate(`/projects/${id}`)();
      };
    } catch ({ message }) {
      toast(message, {
        type: toast.TYPE.ERROR,
      });
    }
  }

  /* Handle project removable */
  async function handleRemove() {
    try {
      await projectService.destroy(data?.id);
      navigate("/projects")();
    } catch ({ message }) {
      toast(message, {
        type: toast.TYPE.ERROR,
      });
    }
  }

  /* Form initial value */
  const initialValue = useMemo(
    function () {
      return data && !Array.isArray(data)
        ? data
        : {
            title: "",
            description: "",
          };
    },
    [data]
  );

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit} initialValue={initialValue}>
        <Form.Col>
          <Card>
            <Card.Head>
              <Card.Title>Projeto</Card.Title>
              <Protected resource="projects" action="update">
                <Submit>Salvar projeto</Submit>
              </Protected>
            </Card.Head>
            <Card.Body className="pb-4">
              <Input.Text name="title" label="Nome do projeto *" isRequired />
              <Editor name="description" label="Descrição" />
              <Protected resource="projects" action="update" elseIf>
                <Input.Hidden name="authorization" isRequired />
              </Protected>
            </Card.Body>
          </Card>
        </Form.Col>
      </Form>
      <Protected resource="projects" action="destroy">
        {data?.id && (
          <div className="px-1 py-4">
            <Removable onConfirm={handleRemove} value="Remover projeto" />
          </div>
        )}
      </Protected>
    </React.Fragment>
  );
}

export default Create;
