import styled from "styled-components";

export const Content: any = styled.div`
  position: relative;
`;

Content.Amount = styled.div`
  width: calc(100% - 20px);
  position: absolute;
  text-align: center;
  font-size: 30px;
  color: #ff4080;
  top: 44%;
`;
