import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

export const Link = styled(RouterLink)`
  color: var(--gray-700);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: var(--pink-500);
  }
`;
