import { LOADING, LOAD_PERSONAS } from "../types";

const INITIAL_STATE = {
  personas: [],
  loading: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_PERSONAS:
      return { ...state, personas: action.payload };
    case LOADING:
      return { ...state, loading: action.payload };
  }
  return { ...state };
}
