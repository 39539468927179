import React, { useState, useEffect } from "react";

import { Container, Input, Icon } from "./styles";
import { BsSearch } from "react-icons/bs";
import debounce from "lodash/debounce";

const handleInput = debounce(function (value, onInput) {
  if (onInput) {
    onInput(value);
  }
}, 500);

function SearchInput({ onInput, size, ...props }) {
  const [input, setInput] = useState<string>("");

  /**
   * Handle input change
   *
   */
  function handleChange(e) {
    setInput(e.target.value);
    handleInput();
  }

  /* Handle input change */
  useEffect(
    function () {
      handleInput(input, onInput);
    },
    [input, onInput]
  );

  return (
    <Container size={size}>
      <Icon>
        <BsSearch />
      </Icon>
      <Input
        onChange={handleChange}
        value={input}
        placeholder="Pesquisar"
        {...props}
      />
    </Container>
  );
}

export default SearchInput as any;
