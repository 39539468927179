import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
`;

export const Nav: any = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
`;

Nav.Item = styled.li`
  border: 1px solid var(--gray-100);
  color: var(--gray-300);
  border-radius: 5px;
  transition: 0.25s;
  display: flex;
  padding: 7px;

  &:hover {
    border: 1px solid var(--pink-300);
    color: var(--pink-300);
    cursor: pointer;
  }
`;

Nav.Text = styled.div`
  flex-grow: 1;
  padding: 0px 10px;
`;

export const Badge = styled.div`
  background: var(--pink-500);
  color: var(--white);
  border-radius: 15px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  height: 20px;
  width: 30px;
`;
