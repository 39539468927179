import React from "react";
import { Editor, Form as Grid, Input, Submit } from "@startaideia/react-forms";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Card, Protected, Removable, useFetch, useNavigate } from "app/shared";
import { personaService } from "services";

function Form() {
  const { project_id, persona_id } = useParams<any>();
  const { data: persona } = useFetch(`/personas/${persona_id}`);
  const { navigate } = useNavigate();

  /**
   * Create a persona
   *
   */
  async function handleSubmit(personaData) {
    try {
      await personaService.save(personaData, persona?.id);
      toast("Persona salva com sucesso", {
        type: toast.TYPE.SUCCESS,
      });
      return function () {
        navigate(`/projects/${project_id}/personas`)();
      };
    } catch (e) {
      toast(e, {
        type: toast.TYPE.ERROR,
      });
    }
  }

  /**
   * Remove a persona
   *
   */
  function handleRemove() {
    personaService.destroy(persona?.id).then(function () {
      navigate(`/projects/${project_id}/personas`)();
    });
  }

  return (
    <React.Fragment>
      <Grid onSubmit={handleSubmit} initialValue={persona}>
        <Grid.Col>
          <Card>
            <Card.Head>
              <Card.Title>Persona</Card.Title>
              <Protected resource="projects" action="update">
                <Submit>Salvar persona</Submit>
              </Protected>
            </Card.Head>
            <Card.Body className="pb-4">
              <Input.Text
                name="title"
                label="Nome"
                help="Ex.: Administrador do sistema"
                isRequired
              />
              <Editor
                name="description"
                label="Descrição"
                props={{ rows: 5 }}
                isRequired
              />
              <Input.Hidden name="project_id" initialValue={project_id} />
              <Protected resource="projects" action="update" elseIf>
                <Input.Hidden name="authorization" isRequired />
              </Protected>
            </Card.Body>
          </Card>
        </Grid.Col>
      </Grid>
      <Protected resource="projects" action="destroy">
        {persona?.id && (
          <div className="px-1 py-4">
            <Removable onConfirm={handleRemove} value="Remover persona" />
          </div>
        )}
      </Protected>
    </React.Fragment>
  );
}

export default Form as any;
