import ResourceService from "../resourceService";

export class MediaService extends ResourceService {
  /**
   * Resource route
   *
   */
  public route: string = "medias";
}

export default new MediaService();
