import React, { useState, useMemo, useCallback } from "react";
import SunEditor from "suneditor-react";

import {
  Form,
  useControl,
  parseClassName,
  useValidation,
} from "@startaideia/react-forms";
import { authService } from "services";

function Editor({
  name,
  initialValue = "",
  label = "",
  xs = 12,
  sm = undefined,
  md = undefined,
  lg = undefined,
  xl = undefined,
  onChange = undefined,
  onFocus = undefined,
  onBlur = undefined,
  help = undefined,
  props,
  ...rest
}) {
  const { setValue, value } = useControl(name, { initialValue });
  const { errors, valid, invalid } = useValidation(name, rest);
  const [touched, setTouched] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);

  /* First error */
  const error = useMemo(() => errors[0], [errors]);

  /* Input class name */
  const className = useMemo(
    () => parseClassName({ valid, focus, touched, invalid } as any),
    [focus, touched, invalid, valid]
  );

  /**
   * Handle input change
   *
   */
  const handleChange = useCallback(
    function (content: string) {
      setValue(content);
      if (onChange) {
        (onChange as any)(content);
      }
    },
    [onChange, setValue]
  );

  /**
   * Handle input focus
   *
   */
  const handleFocus = useCallback(
    function () {
      setFocus(true);
      if (onFocus) {
        (onFocus as any)();
      }
    },
    [setFocus, onFocus]
  );

  /**
   * Handle input blur
   *
   */
  const handleBlur = useCallback(
    function () {
      setTouched(true);
      setFocus(false);
      if (onBlur) {
        (onBlur as any)();
      }
    },
    [setFocus, setTouched, onBlur]
  );

  return (
    <Form.Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <div className={`stf-form-group ${className}`}>
        {label && (
          <label className={`stf-form-label ${className}`}>{label}</label>
        )}
        {touched && invalid && (
          <span className={`stf-form-text stf-text-invalid ${className}`}>
            {error}
          </span>
        )}
        {!(touched && invalid) && help && (
          <span className={`stf-form-text stf-text-muted ${className}`}>
            {help}
          </span>
        )}
        <SunEditor
          setContents={value || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          setOptions={{
            imageUploadHeader: {
              Authorization: `Bearer ${authService.token}`,
            },
            height: 300,
            imageUploadUrl: `${process.env.REACT_APP_SERVER_URL}/api/medias/upload`,
            buttonList: [
              ["undo", "redo"],
              [
                ":p-More Paragraph-default.more_paragraph",
                "font",
                "fontSize",
                "formatBlock",
                "paragraphStyle",
                "blockquote",
              ],
              ["bold", "underline", "italic", "strike"],
              ["fontColor", "hiliteColor", "textStyle"],
              ["removeFormat"],
              ["align", "horizontalRule", "list", "lineHeight"],
              [
                "-right",
                ":r-More Rich-default.more_plus",
                "table",
                "imageGallery",
              ],
              ["-right", "image", "video", "audio", "link"],
            ],
          }}
        />
      </div>
    </Form.Col>
  );
}

export default Editor as any;
