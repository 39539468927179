import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  Form as FormContainer,
  Editor,
  Submit,
  Input,
} from "@startaideia/react-forms";

import { Card, Removable, Protected, useFetch, useNavigate } from "app/shared";
import { platformsActions, requisitesActions } from "store";
import { requisiteService } from "services";

function Form({ platformId, loadPlatform }) {
  const { href } = useNavigate();
  const { id, requisite_id } = useParams<any>();
  const { data: requisite } = useFetch(`/requisites/${requisite_id}`);

  /* Load the current platform */
  useEffect(
    function () {
      loadPlatform(id);
    },
    [id, loadPlatform]
  );

  /**
   * Handle form submit
   *
   */
  async function handleSubmit(requisiteData) {
    try {
      await requisiteService.save(requisiteData, requisite?.id);
      toast("Requisito salvo com sucesso!", {
        type: toast.TYPE.SUCCESS,
      });
      href(`/platforms/${id}/requisites`);
    } catch ({ message }) {
      toast(message, {
        type: toast.TYPE.ERROR,
      });
    }
  }

  /**
   * Handle version remove
   *
   */
  async function handleRemove() {
    try {
      await requisiteService.destroy(requisite?.id);
      toast("Requisito removido com sucesso!", {
        type: toast.TYPE.SUCCESS,
      });
      href(`/platforms/${id}/requisites`);
    } catch ({ message }) {
      toast(message, {
        type: toast.TYPE.ERROR,
      });
    }
  }

  return (
    <React.Fragment>
      <Card>
        <FormContainer onSubmit={handleSubmit} className="p-2 pb-4">
          <FormContainer.Col>
            <Card.Head>
              <Card.Title>Requisito</Card.Title>
              <Submit>Salvar requisito</Submit>
            </Card.Head>
            <Card.Body>
              <Input.Text
                name="title"
                label="Título"
                initialValue={requisite?.title || ""}
                isRequired
              />
              <Editor
                name="description"
                label="Descrição"
                initialValue={requisite?.description || ""}
                props={{ rows: 5 }}
              />
              <Input.Hidden name="platform_id" initialValue={id} />
            </Card.Body>
          </FormContainer.Col>
        </FormContainer>
      </Card>
      <Protected resource="requisites" action="destroy">
        {requisite?.id && (
          <FormContainer.Col xs={12} className="py-3">
            <Removable onConfirm={handleRemove} value="Remover item" />
          </FormContainer.Col>
        )}
      </Protected>
    </React.Fragment>
  );
}

function mapStateToProps({ requisites }, { requisite }) {
  return {
    requisites: requisites.requisites,
    requisite: {
      ...requisite,
      description: requisite?.description || "",
    },
  };
}

function mapDispatchToProps(dispatch, { requisite }) {
  return {
    loadPlatform: (id) => dispatch(platformsActions.loadPlatform(id)),
    pushRequisite: (requisite) =>
      dispatch(requisitesActions.pushRequisite(requisite)),
    popRequisite: () => dispatch(requisitesActions.popRequisite(requisite)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps as any)(Form) as any;
