import styled from "styled-components";

export const Container = styled.div`
  padding-top: 10px;
`;

export const Flex = styled.div`
  justify-content: space-between;
  display: flex;
`;

export const ScrollContainer = styled.div`
  padding-bottom: 10px;
  white-space: nowrap;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-100);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--gray-300);
    cursor: pointer;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const List: any = styled.ul`
  list-style: none;
  display: flex;
  padding: 0px;
  margin: 0px;
`;

List.Item = styled.li`
  box-shadow: 1px 2px 2px var(--gray-100);
  border: 1px solid var(--gray-200);
  background: var(--white);
  border-radius: 5px;
  margin-top: 10px;
  min-width: 150px;
  margin: 5px;
`;

List.Item.Header = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 10px;
`;

List.Item.Body = styled.div`
  white-space: normal;
  padding: 10px 10px;
`;

List.Item.Footer = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 10px;
  padding-top: 0px;
`;

List.Item.Icon = styled.span`
  color: var(--gray-400);
  transition: 0.25s;

  &:hover {
    color: var(--gray-800);
    transform: scale(1.1);
    cursor: pointer;
  }

  &:active {
    transform: scale(1);
  }
`;
