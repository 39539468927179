import { useModal } from "app/shared";
import React from "react";
import { GoLock } from "react-icons/go";
import PasswordModal from "../PasswordModal";

import { Card } from "./styles";

function ActionsCard() {
  const { openModal } = useModal();

  return (
    <Card>
      <Card.Title>Meu perfil</Card.Title>
      <Card.Body>
        <Card.Item onClick={() => openModal(<PasswordModal />)}>
          <Card.Slot>
            <GoLock />
          </Card.Slot>
          <Card.Slot>Alterar senha</Card.Slot>
        </Card.Item>
      </Card.Body>
    </Card>
  );
}

export default ActionsCard;
