import ResourceService from "../resourceService";

export class VersionService extends ResourceService {
  /**
   * Resource route
   *
   */
  public route: string = "versions";
}

export default new VersionService();
