import styled from "styled-components";

export const Container = styled.div`
  padding-top: 70px;
`;

export const Aside: any = styled.div`
  border-right: 1px solid var(--gray-50);
  overflow-x: hidden;
  overflow-y: scroll;
  transition: 0.5s;
  min-width: 250px;
  max-width: 250px;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-100);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--gray-300);
    cursor: pointer;
  }

  &.hidden {
    margin-right: -250px;
  }
`;

export const Main = styled.div`
  overflow-x: hidden;
  padding: 10px;
  flex-grow: 1;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-100);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--gray-300);
    cursor: pointer;
  }
`;
