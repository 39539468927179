import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { FaTrashAlt } from "react-icons/fa";

import { Button, Remove } from "./styles";
import { Text } from "app/shared";

function Removable({
  value,
  onConfirm,
  children = undefined,
  size = 12,
  ...props
}: any) {
  function handleClick() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Text
              value="Tem certeza?"
              display="block"
              size="24"
              weight="600"
              color="--gray-400"
            />
            <Text
              display="block"
              size="16"
              className="py-3"
              color="--gray-300"
              value="Você quer mesmo realizar essa ação? Lembre-se: ela não poderá ser desfeita!"
            />
            <Button
              className="mr-2"
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              Sim, remover!
            </Button>
            <Button className="default" onClick={onClose}>
              Não
            </Button>
          </div>
        );
      },
    });
  }
  return (
    <Remove onClick={handleClick} {...props}>
      {children && children}
      {!children && (
        <React.Fragment>
          <FaTrashAlt className="mr-2" />
          <Text value={value} size={size} />
        </React.Fragment>
      )}
    </Remove>
  );
}

export default Removable;
