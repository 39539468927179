import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";

import { authService } from "services";

function GuestGuard({ ...props }: any) {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState();

  useEffect(function () {
    authService.refreshToken().then(function (token) {
      setToken(token);
      setLoading(false);
    });
  }, []);

  function handleRender() {
    if (loading) {
      return <p>carregando</p>;
    }
    if (token) {
      return <Redirect to="/" />;
    }
    return <Route {...props} />;
  }

  return handleRender();
}

export default GuestGuard;
