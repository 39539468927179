import styled from "styled-components";

export const List: any = styled.ul`
  padding-left: 8px;
  list-style: none;
  margin-top: 20px;
`;

List.Item = styled.li`
  transition: 0.25s;
  display: flex;

  &:hover {
    color: var(--pink-400);
    cursor: pointer;
  }
`;

List.Icon = styled.div`
  align-items: center;
  font-size: 24px;
  display: flex;
  padding: 5px;
`;

List.Text = styled.div`
  align-items: center;
  padding-left: 5px;
  font-weight: 100;
  font-size: 16px;
  display: flex;
`;

List.Header = styled.li`
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: 12px;
`;
