import ResourceService from "../resourceService";

export class StageService extends ResourceService {
  /**
   * Resource route
   *
   */
  public route: string = "stages";
}

export default new StageService();
