import { useNavigate } from "app/shared";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { BsFillKanbanFill } from "react-icons/bs";

import { DropdownButton, Container, Dropdown } from "./style";

function ActionsDropDown({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const { navigate } = useNavigate();
  const domRef = useRef<any>(null);

  /* Handle click outside to close modal */
  const handleClickOutside = useCallback(
    function (e) {
      if (!domRef.current) return;

      if (isOpen && !domRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    },
    [setIsOpen, domRef, isOpen]
  );

  /* Listens to mousedown event */
  useEffect(
    function () {
      document.addEventListener("mousedown", handleClickOutside);
      return function () {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    },
    [handleClickOutside]
  );

  return (
    <Container ref={domRef}>
      <DropdownButton
        className={isOpen && "open"}
        onClick={() => setIsOpen(!isOpen)}
      >
        {children}
      </DropdownButton>
      <Dropdown className={isOpen && "open"}>
        <Dropdown.Item onClick={navigate("/projects/create")}>
          <Dropdown.Slot className="start">
            <BsFillKanbanFill />
          </Dropdown.Slot>
          <Dropdown.Slot className="end">
            Projeto
            <p>Criar um novo projeto dentro da plataforma</p>
          </Dropdown.Slot>
        </Dropdown.Item>
      </Dropdown>
    </Container>
  );
}

export default ActionsDropDown as any;
