import React, { useEffect } from "react";
import { FaCogs } from "react-icons/fa";
import { connect } from "react-redux";

import { Card, Button, Empty, Protected, Text, useNavigate } from "app/shared";
import { platformsActions, requisitesActions } from "store";
import { useParams } from "react-router-dom";

function List({ requisites, loadPlaform, loadRequisite }) {
  const { id } = useParams<any>();
  const { navigate } = useNavigate();

  /* */
  useEffect(
    function () {
      loadRequisite(id);
      loadPlaform(id);
    },
    [loadRequisite, loadPlaform, id]
  );

  return (
    <Card>
      <Card.Head>
        <Card.Title>Requisitos</Card.Title>
      </Card.Head>
      <Card.Body>
        <Protected resource="requisites" action="create">
          <Button onClick={navigate(`/platforms/${id}/requisites/form`)}>
            Criar requisito
          </Button>
        </Protected>
      </Card.Body>

      {requisites?.length === 0 && (
        <Card.Body>
          <Empty />
        </Card.Body>
      )}

      {requisites?.length > 0 && (
        <Card.Table>
          <Card.Table.Head>
            <Card.Table.Head.Row>
              <Card.Table.Head.Cell>Requisito</Card.Table.Head.Cell>
              <Protected resource="requisites" action="update">
                <Card.Table.Head.Cell />
              </Protected>
            </Card.Table.Head.Row>
          </Card.Table.Head>
          <Card.Table.Body>
            {requisites.map(function (requisite) {
              return (
                <Card.Table.Body.Row key={requisite.id}>
                  <Card.Table.Body.Cell>
                    <div className="flex">
                      <Card.Table.Avatar name={requisite.title} size={32} />
                      <div>
                        <div className="mb-1">
                          #{requisite.id} {requisite.title}
                        </div>
                        <Text value={requisite.description} display="block" />
                      </div>
                    </div>
                  </Card.Table.Body.Cell>
                  <Protected resource="requisites" action="update">
                    <Card.Table.Body.Cell>
                      <div className="flex flex-end pr-3">
                        <FaCogs
                          onClick={navigate(
                            `/platforms/${id}/requisites/form/${requisite.id}`
                          )}
                        />
                      </div>
                    </Card.Table.Body.Cell>
                  </Protected>
                </Card.Table.Body.Row>
              );
            })}
          </Card.Table.Body>
        </Card.Table>
      )}
    </Card>
  );
}

function mapStatetoProps({ requisites }) {
  return {
    requisites: requisites.requisites,
  };
}

function mapDisptachToProps(dispatch) {
  return {
    loadRequisite: (id) => dispatch(requisitesActions.loadRequisites(id)),
    loadPlaform: (id) => dispatch(platformsActions.loadPlatform(id)),
  };
}

export default connect(mapStatetoProps, mapDisptachToProps)(List) as any;
