import React, { useState, useRef, useEffect, useCallback } from "react";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import ptBrStrings from "react-timeago/lib/language-strings/pt-br";
import { GoBell } from "react-icons/go";
import TimeAgo from "react-timeago";

import { DropdownButton, Container, Dropdown } from "./style";
import { useFetch } from "app/shared";

const formatter = buildFormatter(ptBrStrings);

function NotificationsDropDown({ children }) {
  const { data } = useFetch("/auth/notifications");
  const [isOpen, setIsOpen] = useState(false);
  const domRef = useRef<any>(null);

  /* Handle click outside to close modal */
  const handleClickOutside = useCallback(
    function (e) {
      if (!domRef.current) return;

      if (isOpen && !domRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    },
    [setIsOpen, domRef, isOpen]
  );

  /* Listens to mousedown event */
  useEffect(
    function () {
      document.addEventListener("mousedown", handleClickOutside);
      return function () {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    },
    [handleClickOutside]
  );

  return (
    <Container ref={domRef}>
      <DropdownButton
        className={isOpen && "open"}
        onClick={() => setIsOpen(!isOpen)}
      >
        {children}
      </DropdownButton>
      <Dropdown className={isOpen && "open"}>
        {data?.map(function (item) {
          return (
            <Dropdown.Item key={item.id}>
              <Dropdown.Slot className="start">
                <GoBell />
              </Dropdown.Slot>
              <Dropdown.Slot className="end">
                {item.data.title}
                <p>{item.data.description}</p>
                <p>
                  <TimeAgo date={item.created_at} formatter={formatter} />
                </p>
              </Dropdown.Slot>
            </Dropdown.Item>
          );
        })}
      </Dropdown>
    </Container>
  );
}

export default NotificationsDropDown as any;
