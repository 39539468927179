export class ReportService {
  /**
   * Root url
   *
   */
  public root: string;

  /**
   * Constructor method
   *
   */
  constructor() {
    this.root = `${process.env.REACT_APP_SERVER_URL}/api`;
  }

  /**
   * Mount url params
   *
   */
  public mountParams(params): string {
    const result: string[] = [];
    for (const field in params) {
      const value = params[field];
      if (Array.isArray(value)) {
        value.forEach(function (item, index) {
          result.push(`${field}[${index}]=${encodeURI(item)}`);
        });
      } else {
        result.push(`${field}=${encodeURI(value)}`);
      }
    }
    return result.join("&");
  }

  /**
   * Open platform
   *
   */
  public open(path: string, params) {
    window.open(`${this.root}${path}?${this.mountParams(params)}`);
  }
}

export default new ReportService();
