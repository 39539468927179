import ResourceService from "../resourceService";

export class ProjectService extends ResourceService {
  /**
   * Resource route
   *
   */
  public route: string = "projects";
}

export default new ProjectService();
