import ResourceService from "../resourceService";

export class TaskService extends ResourceService {
  /**
   * Resource route
   *
   */
  public route: string = "tasks";
}

export default new TaskService();
