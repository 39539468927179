import React from "react";
import { FaCogs, FaDesktop, FaMobileAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Editor,
  Form as Grid,
  Group,
  Input,
  Submit,
} from "@startaideia/react-forms";

import { platformService } from "services";
import { Flex } from "./styles";
import {
  Card,
  Protected,
  Removable,
  TypeSelector,
  useFetch,
  useNavigate,
} from "app/shared";

function Form() {
  const { project_id, platform_id } = useParams<any>();
  const { data: platform } = useFetch(`/platforms/${platform_id}`);
  const { navigate } = useNavigate();

  /**
   * Create a persona
   *
   */
  async function handleSubmit(platformData) {
    try {
      await platformService.save(platformData, platform?.id);
      toast("Plataforma salva com sucesso", {
        type: toast.TYPE.SUCCESS,
      });
      return function () {
        navigate(`/projects/${project_id}/platforms`)();
      };
    } catch (e) {
      toast(e, {
        type: toast.TYPE.ERROR,
      });
    }
  }

  /**
   * Remove a persona
   *
   */
  function handleRemove() {
    platformService.destroy(platform?.id).then(function () {
      navigate(`/projects/${project_id}/platforms`)();
    });
  }

  return (
    <React.Fragment>
      <Grid onSubmit={handleSubmit} initialValue={platform}>
        <Grid.Col>
          <Card>
            <Card.Head>
              <Card.Title>Plataforma</Card.Title>
              <Protected resource="projects" action="update">
                <Submit>Salvar plataforma</Submit>
              </Protected>
            </Card.Head>
            <Card.Body className="pb-4">
              <Input.Text
                name="title"
                label="Nome"
                help="Ex.: Aplicativo vendedor"
              />
              <Group label="Plataforma" name="channel">
                <Flex>
                  <TypeSelector
                    value="mobile"
                    label="Aplicativo"
                    icon={<FaMobileAlt />}
                  />
                  <TypeSelector
                    value="website"
                    label="Web Site"
                    icon={<FaDesktop />}
                  />
                  <TypeSelector
                    value="service"
                    label="Serviço"
                    icon={<FaCogs />}
                  />
                </Flex>
              </Group>
              <Editor
                name="description"
                label="Descrição"
                props={{ rows: 5 }}
              />
              <Input.Hidden name="project_id" initialValue={project_id} />
              <Protected resource="projects" action="update" elseIf>
                <Input.Hidden name="authorization" isRequired />
              </Protected>
            </Card.Body>
          </Card>
        </Grid.Col>
      </Grid>
      <Protected resource="projects" action="destroy">
        {platform?.id && (
          <div className="px-1 py-4">
            <Removable onConfirm={handleRemove} value="Remover plataforma" />
          </div>
        )}
      </Protected>
    </React.Fragment>
  );
}

export default Form as any;
