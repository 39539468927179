import { LOAD_USER } from "../types";

const INITIAL_STATE = {
  user: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_USER:
      return { ...state, user: action.payload };
  }
  return { ...state };
}
