import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom';
import { GoSignOut } from 'react-icons/go';

import { DropdownButton, Container, Dropdown } from './style';
import { authService } from 'services';

function UserDropDown({ children }) {
    const [isOpen, setIsOpen] = useState(false);
    const domRef = useRef<any>(null);
    const history = useHistory();

    /* Handle click outside to close modal */
    const handleClickOutside = useCallback(function(e) {
        if (!domRef.current) return;

        if (isOpen && !domRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }, [setIsOpen, domRef, isOpen])

    /* Listens to mousedown event */
    useEffect(function() {
        document.addEventListener('mousedown', handleClickOutside);
        return function() {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [handleClickOutside])

    /* Handle logout action */
    const handleLogout = useCallback(function() {
        history.push("/auth/login");
        authService.logout();
    }, [history])

    return (
        <Container ref={domRef} >
            <DropdownButton className={isOpen && 'open'} onClick={() => setIsOpen(!isOpen)}>
                {children}
            </DropdownButton>
            <Dropdown className={isOpen && 'open'}>
                <Dropdown.Item onClick={handleLogout}>
                    <Dropdown.Slot className="start">
                        <GoSignOut />
                    </Dropdown.Slot>
                    <Dropdown.Slot className="end">
                        Sair
                    </Dropdown.Slot>
                </Dropdown.Item>
            </Dropdown>
        </Container>
    )
}

export default UserDropDown as any;
