import styled from "styled-components";

export const Container = styled.div`
  background: rgba(255, 255, 255, 0.8);
  justify-content: center;
  align-items: center;
  position: fixed;
  visibility: hidden;
  transition: 0.5s;
  display: flex;
  height: 100vh;
  width: 100%;
  z-index: 98;
  opacity: 0;
  right: 0px;
  top: 0px;

  &.is-visible {
    visibility: visible;
    opacity: 1;
  }
`;

export const Card: any = styled.div`
  max-width: ${({ modalSize }: any) => modalSize}px;
  box-shadow: 1px 2px 2px var(--gray-100);
  border: 1px solid var(--gray-100);
  max-height: calc(100vh - 20px);
  background: var(--white);
  flex-direction: column;
  border-radius: 10px;
  visibility: hidden;
  margin-top: 150px;
  overflow: hidden;
  display: flex;
  width: 100%;
  opacity: 0;
  z-index: 99;

  &.is-visible {
    visibility: visible;
    margin-top: 0px;
    opacity: 1;
  }
`;

Card.Header = styled.div`
  text-align: right;
  width: 100%;
`;

Card.Body = styled.div`
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-100);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--gray-300);
    cursor: pointer;
  }
`;

export const Close = styled.button`
  border-color: transparent;
  background: transparent;
  color: var(--gray-500);
  font-size: 24px;
  padding: 10px;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;
