import { LOADING } from "store/requisites/types";
import { LOAD_STAGES } from "../types";

const INITIAL_STATE = {
  stages: [],
  loading: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_STAGES:
      return { ...state, stages: action.payload };
    case LOADING:
      return { ...state, loading: action.payload };
  }

  return { ...state };
}
