import { stageService } from "services";
import { LOADING } from "store/requisites/types";
import { LOAD_STAGES } from "../types";

/**
 * Load all stages from api
 *
 */
export function loadStages() {
  return async function (dispatch) {
    dispatch({
      type: LOADING,
      payload: true,
    });
    const stages = await stageService.list();
    dispatch({
      type: LOAD_STAGES,
      payload: [...stages],
    });
    dispatch({
      type: LOADING,
      payload: false,
    });
  };
}
