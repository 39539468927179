import { personaService } from "services";
import { LOAD_PERSONAS, LOADING } from "../types";

/**
 * Load all personas from a project
 *
 */
export function loadPersonas(projectId) {
  return async function (dispatch) {
    dispatch({
      type: LOADING,
      payload: true,
    });
    const personas = await personaService.list(
      `?filter[project_id]=${projectId}`
    );
    dispatch({
      type: LOAD_PERSONAS,
      payload: [...personas],
    });
    dispatch({
      type: LOADING,
      payload: false,
    });
  };
}
