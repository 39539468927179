import React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";

import { Dashboard, Platforms, Personas } from "../screens";
import { ProjectCard, Sidebar } from "../components";
import { Page } from "app/shared";

function Routes() {
  const { project_id } = useParams<any>();
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Page.Aside>
        <ProjectCard project_id={project_id} />
        <Sidebar />
      </Page.Aside>
      <Page.Content>
        <Switch>
          <Route path={`${path}/platforms`} component={Platforms} />
          <Route path={`${path}/personas`} component={Personas} />
          <Route path={`${path}`} component={Dashboard} exact />
        </Switch>
      </Page.Content>
    </React.Fragment>
  );
}

export default Routes;
