import React from "react";

import { ModalProvider } from "app/shared";
import { Container, Aside, Main } from "./styles";
import Navbar from "../Navbar";

function Layout({ children }: any) {
  return (
    <ModalProvider>
      <Navbar />
      <Container>{children}</Container>
    </ModalProvider>
  );
}

Layout.Main = Main;
Layout.Aside = Aside;

export default Layout;
