import { LOAD_USER } from "../types";

/**
 * Load the current user
 *
 */
export function loadUser(user) {
  return {
    type: LOAD_USER,
    payload: user,
  };
}
