import React, { useEffect } from "react";
import { CgUserRemove } from "react-icons/cg";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { mutate } from "swr";

import { Empty, Text, Card, useFetch } from "app/shared";
import { Removable } from "app/screens/App/shared";
import { MemberSearch } from "../../components";
import { memberService } from "services";
import { platformsActions } from "store";

function Team({ loadPlatform, platform }) {
  const { id } = useParams<any>();
  const fetchUrl = platform ? `/platforms/${platform?.id}/members` : "";
  const { data } = useFetch(fetchUrl);

  /* Handle member removal */
  function handleRemoveMember(memberId) {
    return async function () {
      try {
        const { data } = await memberService.removeMember(
          platform?.id,
          memberId
        );
        mutate(`/platforms/${platform?.id}/members`);
        toast(data.message, {
          type: toast.TYPE.SUCCESS,
        });
      } catch ({ message }) {
        toast(message, {
          type: toast.TYPE.ERROR,
        });
      }
    };
  }

  /* Component did mount */
  useEffect(
    function () {
      if (id) {
        loadPlatform(id);
      }
    },
    [loadPlatform, id]
  );

  return (
    <Card>
      <Card.Head>
        <Card.Title>Membros</Card.Title>
        <MemberSearch currentMembers={data} />
      </Card.Head>

      {(!data || data?.length === 0) && (
        <Card.Body>
          <Empty />
        </Card.Body>
      )}

      {data && data.length > 0 && (
        <Card.Table>
          <Card.Table.Body>
            {data?.map(function (member) {
              return (
                <Card.Table.Body.Row key={member.id}>
                  <Card.Table.Body.Cell>
                    <div className="flex">
                      <Card.Table.Gravatar email={member?.email} size={32} />
                      <div>
                        <div className="mb-1">
                          {member.first_name} {member.last_name}
                        </div>
                        <Text value={member.email} display="block" />
                      </div>
                    </div>
                  </Card.Table.Body.Cell>
                  <Card.Table.Body.Cell>
                    <Text value={member.title} display="block" />
                  </Card.Table.Body.Cell>
                  <Card.Table.Body.Cell>
                    <Removable onConfirm={handleRemoveMember(member.id)}>
                      <div className="flex flex-end">
                        <CgUserRemove title="Remove membro" />
                      </div>
                    </Removable>
                  </Card.Table.Body.Cell>
                </Card.Table.Body.Row>
              );
            })}
          </Card.Table.Body>
        </Card.Table>
      )}
    </Card>
  );
}

function mapStateToProps({ platforms }) {
  return {
    platform: platforms.platform,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadPlatform: (id) => dispatch(platformsActions.loadPlatform(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Team) as any;
