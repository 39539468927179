import React from "react";
import { MdDashboard } from "react-icons/md";
import { BsPeople } from "react-icons/bs";
import { connect } from "react-redux";

import { useNavigate } from "app/shared";
import { List } from "./styles";
import { FaList } from "react-icons/fa";

function Sidebar({ platform }) {
  const { navigate } = useNavigate();

  return (
    <List>
      <List.Header>Menu</List.Header>
      <List.Item onClick={navigate(`/platforms/${platform?.id}`)}>
        <List.Icon>
          <MdDashboard />
        </List.Icon>
        <List.Text>Escopo</List.Text>
      </List.Item>
      <List.Item onClick={navigate(`/platforms/${platform?.id}/team`)}>
        <List.Icon>
          <BsPeople />
        </List.Icon>
        <List.Text>Time</List.Text>
      </List.Item>
      <List.Item onClick={navigate(`/platforms/${platform?.id}/requisites`)}>
        <List.Icon>
          <FaList />
        </List.Icon>
        <List.Text>Requisitos</List.Text>
      </List.Item>
    </List>
  );
}

function mapStateToProps({ platforms }) {
  return {
    platform: platforms.platform,
  };
}

export default connect(mapStateToProps)(Sidebar) as any;
