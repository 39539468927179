import styled from "styled-components";

export const Remove = styled.div`
  color: var(--red-500);
  display: inline-flex;
  align-items: center;
  opacity: 0.5;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

export const Button = styled.button`
  border: 1px solid var(--pink-400);
  background: var(--pink-400);
  border-radius: 20px;
  border-radius: 20px;
  color: var(--white);
  padding: 10px 30px;
  font-weight: 600;
  font-size: 16px;
  outline: none;
  height: 40px;
  width: auto;

  &:hover {
    cursor: pointer;
  }

  &.default {
    border-color: var(--gray-50);
    background: var(--gray-50);
    color: var(--gray-500);
  }
`;
