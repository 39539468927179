import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Requisites, Detail, Team } from "../screens";
import { PlatformCard, Sidebar } from "../components";
import { Page } from "app/shared";

function Routes() {
  const { path } = useRouteMatch();

  return (
    <Page.Main>
      <Page.Aside>
        <PlatformCard />
        <Sidebar />
      </Page.Aside>
      <Page.Content>
        <Switch>
          <Route path={`${path}/:id/requisites`} component={Requisites} />
          <Route path={`${path}/:id/team`} component={Team} exact />
          <Route path={`${path}/:id`} component={Detail} exact />
        </Switch>
      </Page.Content>
    </Page.Main>
  );
}

export default Routes;
