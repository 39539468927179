import apiService from "../apiService";

class AuthService {
  /**
   * Get the current token
   *
   */
  public get token(): string | null {
    return localStorage.getItem("__authdb");
  }

  /**
   * Set a token
   *
   */
  public set token(token: string | null) {
    if (token) {
      localStorage.setItem("__authdb", token as string);
    } else {
      localStorage.removeItem("__authdb");
    }
  }

  /**
   * Log a user in
   *
   */
  async login(body: any) {
    try {
      const response = await apiService().post("/auth/login", body);
      const {
        data: {
          data: { api_token },
        },
      } = response;
      this.token = api_token;
      return response;
    } catch (e) {
      const { message } = e?.response?.data || { message: false };
      throw new Error(
        message || "Algo deu errado, por favor tente novamente mais tarde"
      );
    }
  }

  /**
   * Logs the user out
   *
   */
  logout() {
    this.token = null;
  }

  /**
   * Gets the current logged user
   *
   */
  async refreshToken() {
    if (!this.token) {
      return null;
    }

    try {
      const data = await apiService().post("auth/update-token");

      const {
        data: {
          data: { api_token },
        },
      } = data;
      this.token = api_token;

      return data.data.data;
    } catch (e) {
      this.token = null;
      return null;
    }
  }

  /**
   * Send recovery token to user email
   *
   */
  async recoveryPassword(data: any) {
    const response = await apiService().post("/auth/recovery-password", data);
    return response;
  }

  /**
   * Reset user password
   *
   */
  async resetPassword(data: any) {
    try {
      await apiService().post("/auth/reset-password", data);
    } catch (e) {
      const { message } = e?.response?.data || { message: false };
      throw new Error(
        message || "Algo deu errado, por favor tente novamente mais tarde"
      );
    }
  }

  /**
   * Update user password
   *
   */
  async updatePassword(data: any) {
    try {
      await apiService().post("/auth/update-password", data);
    } catch (e) {
      const { message } = e?.response?.data || { message: false };
      throw new Error(
        message || "Algo deu errado, por favor tente novamente mais tarde"
      );
    }
  }
}

export default new AuthService();
