import ResourceService from "../resourceService";

export class RequisiteService extends ResourceService {
  /**
   * Resource route
   *
   */
  public route: string = "requisites";
}

export default new RequisiteService();
