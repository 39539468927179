import React from "react";
import { Form, Select, Group, Submit } from "@startaideia/react-forms";
import { RiTempColdLine } from "react-icons/ri";
import { FaFireAlt } from "react-icons/fa";
import { ImBlocked } from "react-icons/im";
import { connect } from "react-redux";

import { TypeSelector } from "app/shared/components";
import { reportService } from "services";
import { Flex } from "./styles";

function ReportModal({ platforms, versions, stages }) {
  /**
   * Handle form submit
   *
   */
  function handleSubmit(params) {
    reportService.open("/reports/roadmap", params);
  }

  return (
    <Form onSubmit={handleSubmit} className="p-3">
      <Select.Multiple
        isRequired
        name="platforms"
        label="Plataformas"
        initialValue={[]}
      >
        {platforms.map(function (platform) {
          return (
            <Select.Option
              key={platform.id}
              value={platform.id}
              label={platform.title}
            />
          );
        })}
      </Select.Multiple>
      <Select.Multiple
        isRequired
        name="stages"
        label="Estágios"
        initialValue={[]}
      >
        {stages.map(function (stage) {
          return (
            <Select.Option
              key={stage.id}
              value={stage.id}
              label={stage.title}
            />
          );
        })}
      </Select.Multiple>
      <Select.Multiple
        isRequired
        name="versions"
        label="Versões"
        initialValue={[]}
      >
        {versions.map(function (version) {
          return (
            <Select.Option
              key={version.id}
              value={version.id}
              label={version.title}
            />
          );
        })}
      </Select.Multiple>
      <Group
        name="requirement_level"
        isRequired
        label="Nível"
        multiple
        initialValue={[]}
      >
        <Flex>
          <TypeSelector
            value="must_have"
            label="Must Have"
            icon={<FaFireAlt />}
          />
          <TypeSelector
            value="could_have"
            label="Could Have"
            icon={<RiTempColdLine />}
          />
          <TypeSelector
            value="wont_have"
            label="Wont Have"
            icon={<ImBlocked />}
          />
        </Flex>
      </Group>
      <Form.Footer>
        <Submit>Emitir relatório</Submit>
      </Form.Footer>
    </Form>
  );
}

function mapStateToProps({ platforms, stages, versions }) {
  return {
    platforms: platforms.platforms,
    versions: versions.versions,
    stages: stages.stages,
  };
}

export default connect(mapStateToProps)(ReportModal);
