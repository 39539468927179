import { LOAD_PLATFORMS, LOAD_PLATFORM, LOADING } from "../types";
import { platformService } from "services";

/**
 * Load all platforms from a project
 *
 */
export function loadPlatforms(projectId) {
  return async function (dispatch) {
    dispatch({
      type: LOADING,
      payload: true,
    });
    const platforms = await platformService.list(
      `?filter[project_id]=${projectId}`
    );
    dispatch({
      type: LOAD_PLATFORMS,
      payload: [...platforms],
    });
    dispatch({
      type: LOADING,
      payload: false,
    });
  };
}

/**
 * Load platform
 *
 */
export function loadPlatform(platformId = null) {
  return async function (dispatch) {
    if (!platformId) {
      dispatch({
        type: LOAD_PLATFORM,
        payload: null,
      });
      return;
    }

    // Load platform from service
    dispatch({
      type: LOADING,
      payload: true,
    });
    const platform = await platformService.find(platformId);
    dispatch({
      type: LOAD_PLATFORM,
      payload: platform,
    });
    dispatch({
      type: LOADING,
      payload: false,
    });
  };
}
